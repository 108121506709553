<template>
  <div id="ContasPagar" class="container-fundo pa-0" v-bind:style="{ 'background-color': !cod_imovel ? COR_PRINCIPAL : COR_SECUNDARIA }">

    <v-overlay :value="loading_relatorio"><v-progress-circular indeterminate size="64" /></v-overlay>
    
      <v-toolbar-title class="headline lighten-2" v-bind:style="{ 'background-color': !cod_imovel ? COR_PRINCIPAL : COR_SECUNDARIA }">
        <!-- Título da página -->
        <div class="justify-space-between d-flex">
          <v-toolbar-title v-bind:class="!cod_imovel ? 'text-white title-page mt-2 ml-4' : 'text-COR_PRINCIPAL mt-0 ml-4'">Contas a Pagar</v-toolbar-title>

          <v-btn v-if="acao == 'SELECIONAR'" @click="fechar()" icon dark color="#F2F6F7" class="mr-2 mt-2 mb-n2">
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
        </div>

        <!-- Valores de consulta padrao para quando esta na selecao (EX: Pagamento em Lote) -->
        <div v-if="acao == 'SELECIONAR'" class="d-flex ml-4 mt-n3 mb-n4">
          <v-checkbox
              v-model="filtro_mesmo_valor"
              outlined
              dark
              style="width:40px!important"
              color="white"
              label="Filtrar mesmo valor da cobrança"
              class="checkbox-label mr-11"
              @click="filtroMesmoValor()"
              >
            <template v-slot:label>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="text-white body-2">Mesmo valor</span>
                </template>
                Filtra as cobranças com mesmo valor
              </v-tooltip>
            </template>              
          </v-checkbox>

          <v-checkbox
              v-model="filtro_mesma_data_pagto"
              outlined
              dark
              style="width:40px!important"
              color="white"
              label="Mesma data"
              class="checkbox-label ml-11"
              @click="filtroMesmaDataPagto()"
              >
            <template v-slot:label>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="text-white text-capitalize body-2">Mesma data de Pagamento</span>
                </template>
                Filtra as cobranças com mesma de pagamento do extrato
              </v-tooltip>
            </template>              
          </v-checkbox>
        </div>

        <!-- Botões de ação cabeçalho -->
        <v-toolbar-title class="mt-1 mb-6 mr-4 ml-4 pa-0" dark elevation="0" >
          <div class="d-flex " tabindex="-1">
            <!-- Text Field com Consulta ----------------------------------------->
            <v-container class="pa-0 ma-0 mb-7 d-flex">
              <v-menu id="vMenu" ref="vMenu" v-model="menu" bottom right fluid offset-y origin="top" class="pa-0 d-flex flex-wrap" elevation=0 :close-on-content-click="false" @input="abriuConsulta">
                <template v-slot:activator="{ on }">
                  <div ref="vMenuDiv" class="false-filter d-flex align-center" align="center" v-on="on" v-bind:style="{ 'background-color': !cod_imovel ? COR_PRINCIPAL : '#F5F5F5' }">
                    <v-icon class="false-icon ml-3 mr-2" v-bind:style="{ 'color': !cod_imovel ? '#FFFFFF' : COR_PRINCIPAL }">mdi-magnify</v-icon>
                    <div class="false-text flex-wrap" >
                      <v-chip v-if="store_ContasPagar.filtro.cod_empreendcobranca" style="max-width: 250px;"  pill v-on="on" small class="ml-1 mr-1">
                        <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Documento&nbsp;</span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasPagar.filtro.cod_empreendcobranca}}</strong>
                      </v-chip>
                      <v-chip v-if="store_ContasPagar.filtro.situacao" style="max-width: 250px;"  pill v-on="on" small class="ml-1 mr-1">
                        <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Situação&nbsp;</span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasPagar.filtro.situacao}}</strong>
                      </v-chip>
                      <v-chip v-if="store_ContasPagar.filtro.empreendimento" pill v-on="on" small class="ml-1 mr-1 pa-2">
                        <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7">Empr: </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasPagar.filtro.empreendimento.empreend_nome}}</strong>
                      </v-chip>
                      <v-chip v-if="store_ContasPagar.filtro.cliente_nome" style="max-width: 250px;"  pill v-on="on" small class="ml-1 mr-1">
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Cliente&nbsp;</span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasPagar.filtro.cliente_nome}}</strong>
                      </v-chip>
                      <v-chip v-if="store_ContasPagar.filtro.parc_dt_inicial" pill v-on="on" small class="ml-1 mr-1">
                        <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Venc. Inicial>= </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{ formatDate(store_ContasPagar.filtro.parc_dt_inicial) }}</strong>
                      </v-chip>
                      <v-chip v-if="store_ContasPagar.filtro.parc_dt_final" pill v-on="on" small class="ml-1 mr-1">
                        <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Venc Final&#60;= </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{ formatDate(store_ContasPagar.filtro.parc_dt_final) }}</strong>
                      </v-chip>
                      <v-chip v-if="store_ContasPagar.filtro.parc_valor_inicial" style="max-width: 250px;" pill v-on="on" small class="ml-1 mr-1">
                        <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Parc Vl Inicial&nbsp;</span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{formatPrice(store_ContasPagar.filtro.parc_valor_inicial)}}</strong>
                      </v-chip>
                      <v-chip v-if="store_ContasPagar.filtro.parc_valor_final" style="max-width: 250px;"  pill v-on="on" small class="ml-1 mr-1">
                        <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Parc Vl Final&nbsp;</span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{formatPrice(store_ContasPagar.filtro.parc_valor_final)}}</strong>
                      </v-chip>
                      <v-chip v-if="store_ContasPagar.filtro.parc_dt_pagto_inicial" pill v-on="on" small class="ml-1 mr-1">
                        <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Dt Pagto Inicial>= </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{ formatDate(store_ContasPagar.filtro.parc_dt_pagto_inicial) }}</strong>
                      </v-chip>
                      <v-chip v-if="store_ContasPagar.filtro.parc_dt_pagto_final" pill v-on="on" small class="ml-1 mr-1">
                        <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Dt Pagto Final&#60;= </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{ formatDate(store_ContasPagar.filtro.parc_dt_pagto_final) }}</strong>
                      </v-chip>
                      <v-chip v-if="store_ContasPagar.filtro.parc_valor_pagto_inicial" pill v-on="on" small class="ml-1 mr-1">
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Pagto Vl Inicial>= </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{ formatPrice(store_ContasPagar.filtro.parc_valor_pagto_inicial) }}</strong>
                      </v-chip>
                      <v-chip v-if="store_ContasPagar.filtro.parc_valor_pagto_final" pill v-on="on" small class="ml-1 mr-1">
                        <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Pagto Vl Final&#60;= </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{ formatPrice(store_ContasPagar.filtro.parc_valor_pagto_final) }}</strong>
                      </v-chip>
                      <v-chip v-if="store_ContasPagar.filtro.descricao" pill v-on="on" small class="ml-1 mr-1">
                        <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Descrição: </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasPagar.filtro.descricao}}</strong>
                      </v-chip>
                      <v-chip v-if="store_ContasPagar.filtro.nf" pill v-on="on" small class="ml-1 mr-1">
                        <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >NF: </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasPagar.filtro.nf}}</strong>
                      </v-chip>
                      <v-chip v-if="store_ContasPagar.filtro.imovel" pill v-on="on" small class="ml-1 mr-1 pa-2">
                        <span v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7">Imóv/Unid: </span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_ContasPagar.filtro.imovel.nome_imovel}}</strong>
                      </v-chip>
                    </div>
                    <v-icon class="false-icon mr-4 ml-2" v-bind:style="{ 'color': !cod_imovel ? '#FFFFFF' : COR_PRINCIPAL }">mdi-filter-variant-plus</v-icon>
                  </div>
                </template>

                <v-container v-bind:style="{ 'background-color':COR_SECUNDARIA_2 }">
                  <v-row no-gutters class="mb-2 d-flex">
                    <span class="me-5 font-weight body-1" v-bind:style="{ 'color':COR_SUBTITULO }">Cobrança / Documento</span>
                    <v-btn class="mr-2 text-white text-capitalize" :color="COR_PRINCIPAL" @click="vencendo(-1, 'isActiveVencidos')" v-model="isActiveVencidos" active elevation="0" outlined tabindex="-1" small>
                      <span>Em atraso</span>
                    </v-btn>
                    
                    <v-btn class="mr-2 text-white text-capitalize" :color="COR_PRINCIPAL" @click="vencendo(0, 'isActiveHoje')" v-model="isActiveHoje" active elevation="0" outlined tabindex="-1" small>
                      <span>Hoje</span>
                    </v-btn>

                    <v-btn class="mr-2 text-white text-capitalize" :color="COR_PRINCIPAL" @click="vencendo(7, 'isActiveSemana')" v-model="isActiveSemana" active elevation="0" outlined tabindex="-1" small>
                      <span>7 dias</span>
                    </v-btn>

                    <v-btn class="mr-2 text-white text-capitalize" :color="COR_PRINCIPAL" @click="vencendo(30, 'isActiveMes')" v-model="isActiveMes" active elevation="0" outlined tabindex="-1" small>
                      <span>30 dias</span>
                    </v-btn>
                  </v-row>

                  <v-row no-gutters class="linhas">
                    <v-col md="2"> 
                      <!-- Cód Cobrança ----------------------- -->
                      <v-text-field
                        v-model="filtro_local.cod_empreendcobranca"
                        label="Nº Documento/Cobrança"
                        placeholder="Nº Documento/Cobrança"
                        outlined
                        filled
                        background-color="#FFF"
                        dense
                        required
                        flat
                        type="number"
                        clearable
                        tabindex="0"
                        class="mr-2" />
                    </v-col>
                    <v-col md="3"> 
                      <!-- Situação ----------------------- -->
                      <v-select 
                        :items="opcoes_situacao"
                        v-model="filtro_local.situacao"
                        item-text="text"
                        item-value="value"
                        label="Situação"
                        placeholder="Situação"
                        outlined 
                        filled 
                        background-color="#FFF" 
                        dense 
                        required 
                        flat 
                        clearable 
                        tabindex="0"
                        class="mr-2" /> 
                    </v-col>
                    <v-col md="7">
                      <!-- Descricao ----------------------- -->
                      <v-text-field
                        id="txtContrato"
                        ref="txtContrato"
                        dense
                        v-model="filtro_local.descricao"
                        cache-items
                        flat
                        label="Descrição"
                        placeholder="Descrição"
                        outlined
                        filled
                        background-color="#FFF"
                        clearable
                        tabindex="0" />
                    </v-col>
                  </v-row>  

                  <v-row no-gutters class="linhas">
                  <v-col md="2">
                      <!-- Nota Fiscal ----------------------- -->
                      <v-text-field
                        dense
                        v-model="filtro_local.nf"
                        cache-items
                        flat
                        label="Nota Fiscal"
                        placeholder="Nota Fiscal"
                        outlined
                        filled
                        background-color="#FFF"
                        clearable
                        tabindex="0"
                        class="mr-2" />
                    </v-col>
                    <v-col md="5">
                      <v-text-field 
                        id="txtCliente" 
                        ref="txtCliente" 
                        dense 
                        v-model="filtro_local.cliente_nome" 
                        cache-items 
                        flat 
                        label="Favorecido" 
                        placeholder="Favorecido" 
                        outlined 
                        filled 
                        background-color="#FFF" 
                        clearable 
                        tabindex="0"
                        class="mr-2" />
                    </v-col>
                    <v-col md="5">
                      <!-- Imóvel/Unidade ------------------------ -->
                      <v-autocomplete
                        v-if="!cod_imovel"
                        v-model="filtro_local.imovel"
                        :loading="loading"
                        :search-input.sync="searchImoveis"
                        :items="imoveis"
                        no-filter
                        hide-selected
                        clearable
                        label="Imóvel Avulso" 
                        placeholder="Imóvel Avulso" 
                        item-text="nome_imovel" 
                        item-value="cod_imovel"
                        auto-select-first  
                        filled 
                        outlined 
                        dense 
                        return-object
                        background-color="map-deep-merge white" 
                      />
                    </v-col>

                  </v-row>

                  <v-row v-if="!cod_imovel" no-gutters class="linhas">
                    <v-col md="6">
                      <!-- Empreendimento ------------------------ -->
                      <v-autocomplete 
                        :items="empreendimentos" 
                        v-model="filtro_local.empreendimento" 
                        item-text="empreend_nome" 
                        item-value="cod_empreendimento" 
                        label="Empreendimento" 
                        placeholder="Empreendimento" 
                        outlined 
                        filled 
                        background-color="#FFF" 
                        dense 
                        required 
                        flat 
                        clearable 
                        tabindex="0" 
                        return-object
                        class="mr-2" />
                    </v-col>                 
                    <v-col md="3">
                      <!-- Quadra ------------------------ -->
                      <v-autocomplete
                        v-model="filtro_local.quadra"
                        :loading="loading"
                        :items="quadras"
                        no-filter
                        hide-selected
                        clearable
                        class="mr-2"
                        label="Quadra" 
                        placeholder="Quadra" 
                        item-text="quadra" 
                        item-value="cod_imovel"
                        auto-select-first  
                        filled 
                        outlined 
                        dense 
                        return-object
                        background-color="map-deep-merge white" 
                      />
                    </v-col>                 
                    <v-col md="3">
                      <!-- Lote ------------------------ -->
                      <v-autocomplete
                        v-model="filtro_local.lote"
                        :loading="loading"
                        :items="lotes"
                        no-filter
                        hide-selected
                        clearable
                        label="Lote" 
                        placeholder="Lote" 
                        item-text="lote" 
                        item-value="cod_imovel"
                        auto-select-first  
                        filled 
                        outlined 
                        dense 
                        return-object
                        background-color="map-deep-merge white" 
                      />
                    </v-col>
                  </v-row>

                  <v-row no-gutters class="linhas">
                    <v-col md="3">
                      <!-- Vencimento Inicial ----------------------- -->
                      <DatePicker
                        v-model="filtro_local.parc_dt_inicial"
                        label="Dt Venc. Inicial"
                        placeholder="dia/mês/ano"
                        class="mr-2"
                        outlined
                        backgroundColor="#FFF"
                        :style="data_picker"
                        dense />
                    </v-col>
                    <v-col md="3">
                      <!-- Vencimento Final ----------------------- -->
                      <DatePicker
                        v-model="filtro_local.parc_dt_final"
                        label="Dt Venc. Final"
                        placeholder="dia/mês/ano"
                        outlined
                        backgroundColor="#FFF"
                        :style="data_picker"
                        dense
                        class="mr-2" />
                    </v-col>
                    <v-col md="3">
                      <!-- Valor da Parcela Inicial ----------------------- -->
                      <v-text-field
                        dense
                        v-model="filtro_local.parc_valor_inicial"
                        cache-items
                        flat
                        label="Valor Inicial"
                        placeholder="Parc. Valor Inicial"
                        outlined
                        filled
                        type="number"
                        background-color="#FFF"
                        clearable
                        tabindex="0"
                        class="mr-2" />
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        dense
                        v-model="filtro_local.parc_valor_final"
                        cache-items
                        flat
                        label="Valor Final"
                        placeholder="Parc. Valor Final"
                        outlined
                        filled
                        type="number"
                        background-color="#FFF"
                        clearable
                        tabindex="0" />
                    </v-col>
                  </v-row>

                  <v-row no-gutters class="linhas">
                    <v-col md="3">
                      <!-- Data de Pagamento Inicial ----------------------- -->
                      <DatePicker
                        id="txtPagamentoInicial"
                        ref="txtPagamentoInicial"
                        v-model="filtro_local.parc_dt_pagto_inicial"
                        label="Dt Pagto Inicial"
                        placeholder="dia/mês/ano"
                        class="mr-2"
                        outlined
                        backgroundColor="#FFF"
                        :style="data_picker"
                        dense />
                    </v-col>
                    <v-col md="3">
                      <DatePicker
                        id="txtPagamentoFinal"
                        ref="txtPagamentoFinal"
                        v-model="filtro_local.parc_dt_pagto_final"
                        label="Dt Pagto Final"
                        placeholder="dia/mês/ano"
                        outlined
                        backgroundColor="#FFF"
                        :style="data_picker"
                        dense
                        class="mr-2" />
                    </v-col>
                    <v-col md="3">
                      <!-- Valor Pago Inicial ----------------------- -->
                      <v-text-field
                        dense
                        v-model="filtro_local.parc_valor_pagto_inicial"
                        cache-items
                        flat
                        label="Valor Pagto Inicial"
                        placeholder="Valor Pagto Inicial"
                        outlined
                        filled
                        type="number"
                        background-color="#FFF"
                        clearable
                        tabindex="0"
                        class="mr-2" />
                    </v-col>
                    <!-- Valor Pago Final ----------------------- -->
                    <v-col md="3">
                      <v-text-field
                        dense
                        v-model="filtro_local.parc_valor_pagto_final"
                        cache-items
                        flat
                        label="Valor Pagto Final"
                        placeholder="Valor Pagto Final"
                        outlined
                        filled
                        type="number"
                        background-color="#FFF"
                        clearable
                        tabindex="0" />
                    </v-col>
                  </v-row>
                </v-container>

                <v-divider></v-divider>

                <!-- Botao Limpar ------------------------------------>
                <div class="pa-4 d-flex justify-space-between" v-bind:style="{ 'background-color':COR_SECUNDARIA }">
                  <v-btn class="text-white text-capitalize" :color="COR_PRINCIPAL" elevation="0" outlined tabindex="-1" :small="isMobile" @click="limpa_todos()" >
                    <v-icon class="mr-1 icon-filter" color="light-blue accent-2">mdi-close</v-icon>
                    <span>Limpar Todos</span>
                  </v-btn>
                  
                  <!-- Botao Consultar ------------------------------------>
                  <v-btn class="text-white text-capitalize" :color="COR_PRINCIPAL" elevation="0" tabindex="-1" :small="isMobile" @click="busca(1)">
                    <v-icon class="mr-1 icon-filter" color="light-blue accent-2">mdi-magnify</v-icon>
                    <span>Consultar</span>
                  </v-btn>
                </div>  
              </v-menu>
            </v-container>
            <!-- ----------------------------------------------- -->

            <v-spacer></v-spacer>

            <!-- Botão Cesta ------------------------------- -->
            <v-btn  v-if="total_carrinho >= 0 &&
                          store_usuario.priv_busca_privilegios_aplicacao_evento('ContasPagar_pagar_lote') &&
                          acao != 'SELECIONAR'"
                    v-bind:class=" !cod_imovel ? 'text-white mt-1 mb-n2 mr-2' : 'text-none text-COR_PRINCIPAL mt-1 mb-n2 mr-3'"
                    v-bind:color=" !cod_imovel ? COR_PRINCIPAL : ''"
                    @click="dialog_contas_pagar_lote = true"
                    class="text-white mt-1 pt-2 pr-4"
                    elevation="0" :small="isMobile">
              <v-badge v-if="total_carrinho > 0" color="red"  size="small" :content="total_carrinho">
                <v-icon class="mr-1 mt-n2" size="18" v-bind:color=" !cod_imovel ? 'white accent-2' : COR_PRINCIPAL">mdi-cart</v-icon>
              </v-badge>
              <v-icon v-else class="mr-1 mt-n2" size="18" v-bind:color=" !cod_imovel ? 'white accent-2' : COR_PRINCIPAL">mdi-cart</v-icon>
            </v-btn>

            <!-- Botão Transferir ------------------------------- -->
            <v-btn
              v-if="store_usuario.priv_busca_privilegios_aplicacao_evento('ContasPagar_alterar') &&
                    acao == 'SELECIONAR' &&
                    total_parc_selecionado > 0"                   
              @click="selecionar()"
              class="text-white mr-9 mt-1"
              :color="COR_PRINCIPAL"
              elevation="0"
              :small="isMobile"
            >
              <v-icon class="mr-1" color="green accent-2">mdi-cash-refund</v-icon>
              <span class="text-none">Transferir</span>
            </v-btn>

            <!-- Botão Novo ------------------------------- -->
            <v-btn
              v-bind:class=" !cod_imovel ? 'text-white ml-5 mt-1 ContasPagar_novo' : 'text-none text-COR_PRINCIPAL mr-3 mt-1'"
              v-on:click="novo()"
              v-bind:color=" !cod_imovel ? COR_PRINCIPAL : ''"
              elevation="0"
              :small="isMobile"
            >
              <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
              <span>Novo</span>
            </v-btn>

            <!-- Botão Opções ------------------------------- -->
            <v-menu v-if="acao != 'SELECIONAR'" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  v-bind:class=" !cod_imovel ? 'text-white mt-1 mb-n2 mr-2' : 'text-none text-COR_PRINCIPAL mt-1 mb-n2'"
                  v-bind:color=" !cod_imovel ? COR_PRINCIPAL : ''"
                  elevation="0"
                  v-bind="attrs"
                  v-on="on">
                  <v-icon class="mr-1" color="light-blue accent-2">mdi-view-headline</v-icon>
                  <span>Opções</span>
                </v-btn>
              </template>

              <!-- Botões de Pagar em Lote -->
              <v-list>
                <!-- <v-list-item @click="excluirSelecionados()"> -->
                <v-list-item
                  v-if="store_usuario.priv_busca_privilegios_aplicacao_evento('ContasPagar_pagar_lote') && isChecked"
                  @click="dialog_deseja_pagar_em_lote = true">
                  <v-list-item-title>
                    <v-icon color="green" class="mr-2 icon-menu">mdi-cash</v-icon>
                    Pagar em Lote
                  </v-list-item-title>
                </v-list-item>
                <!-- <v-list-item
                  v-if="store_usuario.priv_busca_privilegios_aplicacao_evento('ContasPagar_pagar_lote') && total_carrinho > 0"
                  @click="dialog_limpar_cesta = true">
                  <v-list-item-title>
                    <v-icon color="gray" class="mr-2 icon-menu">mdi-cart-off</v-icon>
                    Limpar cesta
                  </v-list-item-title>
                </v-list-item> -->
                <v-list-item
                  v-if="store_usuario.priv_busca_privilegios_aplicacao_evento('ContasPagar_excluir') && isChecked"
                  @click="dialog_excluir_selecionados_confirmacao = true">
                  <v-list-item-title >
                    <v-icon color="red" class="mr-2 icon-menu">mdi-delete</v-icon>
                    Excluir
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="store_usuario.priv_busca_privilegios_aplicacao_evento('ContasPagar_visualizar')">
                  <v-list-item-title>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-title elevation="0" v-bind="attrs" v-on="on" >
                          <v-icon color="blue" class="mr-2 icon-menu">mdi-download</v-icon>
                          <span>Exportar</span>
                        </v-list-item-title>
                      </template>

                      <!-- Botões do Exportação -->
                      <v-list>
                        <v-list-item @click="export_table(cobrancas, 'xls', 'contas_pagar')">
                          <v-list-item-title>
                            <v-icon color="green" class="mr-2 icon-menu">mdi-file-excel</v-icon>
                            Excel
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="export_table(cobrancas, 'csv', 'contas_pagar')">
                          <v-list-item-title>
                            <v-icon color="green" class="mr-2 icon-menu">mdi-microsoft-excel</v-icon>
                            CSV
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="export_table(cobrancas, 'txt', 'contas_pagar')">
                          <v-list-item-title >
                            <v-icon color="gray" class="mr-2 icon-menu">mdi-file-document</v-icon>
                            TXT
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <!-- Botão Selecionar ------------------------------- -->
          <!-- <div style="background-color:transparent" class="mt-n5 mb-5">
            <v-container class="pa-0 ma-0">
              <v-btn v-if="acao == 'SELECIONAR'" v-on:click="selecionar()" class="text-white" :color="COR_PRINCIPAL" elevation="0" :small="isMobile">
                <v-icon class="mr-1" color="green accent-2">mdi-</v-icon>
                <span class="text-none">Transferir Cobrança Selecionada</span>
              </v-btn>
            </v-container>
          </div> -->

        </v-toolbar-title>
        <!-- Fim Botões de ação cabeçalho -->

      </v-toolbar-title>


      <v-row class=" mt-n11 w-100 mx-0">
        <!-- Grade ----------------------------------->
        <v-col cols="12" class="pa-0 mx-0 w-100">
          <v-container class="container-principal container-rounded px-0 w-100 mx-0">

          <!-- Conteiner  -->
           <v-data-table
             id="dataTable"
             ref="dataTable"
             v-model="selected"
             :items="filteredCobrancas"
             selectable-key="isSelectable"
             style="white-space: nowrap"
             no-sorting
             :loading="loading"
             :headers="headers"
             fixed-header
             dense
             :height="tableHeight"
             hide-default-footer
             :page.sync="currentPage" 
             :hide-default-header="true"
             loading-text="Carregando...  aguarde..."
             no-data-text="Nenhum Registro Encontrado. Use os campos acima para refinar sua consulta"
             no-results-text="Nenhum Registro Encontrado. Use os campos acima para refinar sua consulta`"
             show-select
             item-key="cod_empreendcobranca"
             :items-per-page="20"
             :footer-props="cobrancasTableFooterProps"
             class="data-table">
              <template v-slot:header>
                <thead>
                  <tr>
                    <th v-for="h in headers" :key="h.value" :class="h.class" :style="h.style">
                      <v-checkbox v-if="h.value == 'checkbox'"
                                  show-select hide-details class="checkbox"
                                  color="primary"
                                  @change="selecionaTodos($event)"
                                  style="width:40px!important" />
                      <span v-else class="d-flex flex-nowrap body-2 font-weight-bold">{{h.text}}</span>
                    </th>
                  </tr>
                </thead>
              </template>

              <template v-slot:item="{index, item}">
                <tr>
                  <td @click="store_ContasPagar.dialogFiltroAvancado = false">
                    <v-checkbox
                      v-model="item.value"
                      show-select
                      hide-details
                      class="checkbox"
                      @click="rodape()"
                    />
                  </td>
                  <td style="max-width: 35px">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="grey" dark icon v-bind="attrs" v-on="on" class="ml-n4">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          class="d-none"
                          v-if="store_usuario.priv_busca_privilegios_aplicacao_evento('ContasPagar_alterar') &&
                                acao == 'SELECIONAR' &&
                                !item.cod_extrato_movimento &&
                                !itens_array_pai.includes(item.cod_empreendcobranca)"
                          @click="selecionar()">
                          <!-- Botao Selecionar -->
                          <v-list-item-title>
                            <v-icon color="primary" class="mr-2 icon-menu">mdi-arrow-left-circle</v-icon>
                              Selecionar este lançamento
                          </v-list-item-title>
                        </v-list-item>

                        <v-divider 
                          v-if=" acao == 'SELECIONAR' &&
                                          !item.cod_extrato_movimento &&
                                          !itens_array_pai.includes(item.cod_empreendcobranca)"
                                    class="d-none my-3"></v-divider>

                        <v-list-item
                          v-if="item.empreendcobr_situacao == 'Aberto' &&
                                store_usuario.priv_busca_privilegios_aplicacao_evento('ContasPagar_alterar')"
                          @click="editar(item)">
                          <!-- Botao Editar -->
                          <v-list-item-title>
                            <v-icon color="green" class="mr-2 icon-menu">mdi-pencil</v-icon>
                              Editar
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                          v-if="item.empreendcobr_situacao == 'Aberto' &&
                                store_usuario.priv_busca_privilegios_aplicacao_evento('ContasPagar_pagar')"
                          @click="pagar(item)">
                          <v-list-item-title>
                            <v-icon color="green" class="mr-2 icon-menu">mdi-cash-multiple</v-icon> Pagar
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                          v-if="item.empreendcobr_situacao == 'Pago' &&
                                store_usuario.priv_busca_privilegios_aplicacao_evento('ContasPagar_visualizar')"
                          @click="imprime_recibo(item.cod_empreendcobranca)"
                          :loading="loading_relatorio">
                          <v-list-item-title>
                            <v-icon color="blue" class="mr-2 icon-menu">mdi-printer</v-icon> Imprimir Recibo
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                          v-if="item.empreendcobr_situacao == 'Aberto'  && 
                                store_usuario.priv_busca_privilegios_aplicacao_evento('ContasPagar_excluir')"
                          @click="dialog_excluir_confirmacao_1 = item.cod_empreendcobranca">
                          <v-list-item-title>
                            <v-icon color="red" class="mr-2 icon-menu">mdi-delete</v-icon> Excluir
                          </v-list-item-title>
                        </v-list-item>

                       <v-list-item
                         v-if="!item.cod_extrato_movimento && item.empreendcobr_situacao == 'Pago' && 
                                store_usuario.priv_busca_privilegios_aplicacao_evento('ContasPagar_estornar')"
                         @click="dialog_estornar_confirmacao_1 = item">
                          <v-list-item-title>
                            <v-icon color="red" class="mr-2 icon-menu">mdi-arrow-u-left-top-bold</v-icon>Estornar
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                          v-if="item.empreendcobr_situacao == 'Pago' && 
                                store_usuario.priv_busca_privilegios_aplicacao_evento('ContasPagar_visualizar')"
                          @click="pagar(item)">
                          <v-list-item-title >
                            <v-icon color="blue" class="mr-2 icon-menu">mdi-magnify</v-icon>Visualizar
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                          @click="documentos(item)"
                          v-if="store_usuario.priv_busca_privilegios_aplicacao_evento('ContasPagar_visualizar')">
                          <v-list-item-title >
                            <v-icon color="green" class="mr-2 icon-menu">mdi-badge-account</v-icon>Documentos
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="duplicar(item)">
                          <v-list-item-title >
                            <v-icon color="gray" class="mr-2 icon-menu">mdi-equal-box</v-icon>Duplicar Cobrança
                          </v-list-item-title>
                        </v-list-item>

                        <!-- <v-list-item
                          v-if="item.lote_cesta_usuario &&
                                !item.lote_cesta_numero_gerado &&
                                store_usuario.priv_busca_privilegios_aplicacao_evento('ContasPagar_pagar_lote')"
                          @click="dialog_limpar_item_cesta = true; cod_empreendcobranca_aux = item.cod_empreendcobranca">
                          <v-list-item-title >
                            <v-icon color="#808080" class="mr-2 icon-menu">mdi-cart-off</v-icon>Limpar cobrança da cesta
                          </v-list-item-title>
                        </v-list-item> -->
                      </v-list>
                    </v-menu>
                  </td>
                  <td align="right">
                    
                    <v-tooltip top v-if="item.cod_extrato_movimento">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small v-bind="attrs" v-on="on">mdi-link-variant</v-icon>
                      </template>
                      <span>Cobrança Conciliada</span>
                    </v-tooltip>
                   <!-- <pre>{{ item }}</pre> -->
                    <v-tooltip top v-if="item.empreendcobr_situacao == 'Aberto' && !!item.lote_agendamento_dt">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small v-bind="attrs" v-on="on">mdi-cart</v-icon>
                      </template>
                      <span>Cobrança no carrinho</span>
                    </v-tooltip>
                    
                    <v-tooltip v-if="Object.keys(item.docs).length > 0" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small v-bind="attrs" v-on="on">mdi-receipt-text</v-icon>
                      </template>
                      <span v-for="h in Object.entries(item.docs)" :key="h[0]">{{ h[0] }}: {{ h[1] }}<br></span>
                    </v-tooltip>
                    {{ item.cod_empreendcobranca }}
                  </td> 
                  <td align="center">
                    <span
                      v-if="item.empreendcobr_situacao == 'Aberto' &&
                            moment(item.empreendcobr_parc_dt).format() >= moment(moment(new Date()).format('YYYY-MM-DD')).format()"
                      class="status_aberto_vencer">
                      <v-icon color="#FFFFFF" class="ml-n2" dense >
                        mdi-alarm
                      </v-icon>
                      {{ item.empreendcobr_situacao }}
                    </span>

                    <span
                      v-if="item.empreendcobr_situacao == 'Aberto' &&
                            moment(item.empreendcobr_parc_dt).format() < moment(moment(new Date()).format('YYYY-MM-DD')).format()"
                      class="status_aberto_vencida"
                    ><v-icon color="#FFFFFF" class="ml-n2" dense >mdi-alert-octagon-outline</v-icon>{{ item.empreendcobr_situacao }}
                    </span>

                    <span
                      v-if="item.empreendcobr_situacao == 'Pago'"
                      class="status_pago"
                      ><v-icon color="#FFFFFF" class="ml-n2" dense >mdi-check</v-icon>{{ item.empreendcobr_situacao }}
                    </span>

                    <span
                      v-if="item.empreendcobr_situacao == 'Rescindido'"
                      class="status_rescindido"
                      >{{ item.empreendcobr_situacao }}
                    </span>

                    <span
                      v-if="item.empreendcobr_situacao == 'Inativo'"
                      class="status_inativo"
                      >{{ item.empreendcobr_situacao }}
                    </span>

                  </td>
                  <!-- <td align="center">{{ item.cob_item.length }}</td> -->
                  <!-- <pre>{{ item }}</pre> -->
                  <td align="left">{{ item.empreendcobr_parcela_inicial }} / {{ item.empreendcobr_parcela_final }}</td>
                  <td align="center">{{ formatDate(item.empreendcobr_parc_dt) }}</td>
                  <td align="right">{{ item.empreendcobr_parc_valor ? formatPrice(item.empreendcobr_parc_valor) : '' }}</td>
                  <td>{{ item.empreendcobr_pagto_dt ? formatDate(item.empreendcobr_pagto_dt) : '' }}</td>

                  <td align="right">
                    <v-tooltip top v-if=" item.empreendcobr_parc_acres_valor ||
                                          item.empreendcobr_parc_desc_valor ||
                                          item.empreendcobr_pagto_valor_muljur
                                          ">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small v-bind="attrs" v-on="on" color="red">mdi-cash</v-icon>
                      </template>
                      <span v-if="item.empreendcobr_parc_acres_valor"   align="right">{{ 'Acréscimo: ' + formatPrice(item.empreendcobr_parc_acres_valor)}}<br></span>
                      <span v-if="item.empreendcobr_parc_desc_valor"    align="right">{{ 'Desconto: ' + formatPrice(item.empreendcobr_parc_desc_valor)  }}<br></span>
                      <span v-if="item.empreendcobr_pagto_valor_muljur"  align="right">{{ 'Multa: ' + formatPrice(item.empreendcobr_pagto_valor_muljur)   }}<br></span>
                    </v-tooltip>
                    {{ item.empreendcobr_pagto_valor ? formatPrice(item.empreendcobr_pagto_valor) : '' }}
                  </td>
                  <td align="left" style="">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span style="font-size:12px"  v-bind="attrs" v-on="on">
                          {{ item.empreendcobr_descricao.substring(0, item.empreendcobr_nf ? 45 : 55) }} 
                          {{ item.empreendcobr_descricao.length > 45 ? '...' : '' }} {{item.empreendcobr_nf ? `(NF: ${item.empreendcobr_nf})` : ''}}</span>
                      </template>
                      <span style="font-size:12px">{{item.empreendcobr_descricao}} {{item.empreendcobr_nf ? `(NF: ${item.empreendcobr_nf})` : ''}}</span>
                    </v-tooltip>
                  </td>
                  <td align="left">
                    {{ item.cobr_dev.pessoa_nome }}
                    <div v-if="item.cobr_dev.cod_pessoa !== item.cobr_pag.cod_pessoa && item.empreendcobr_situacao == 'Pago'">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span style="font-size:12px"  v-bind="attrs"
                            v-on="on">{{ item.cobr_pag.pessoa_nome.substring(0, 35) }}{{  item.cobr_pag.pessoa_nome.length > 35 ? '...' : '' }}</span>
                        </template>
                        <span style="font-size:12px">Pagador: {{ item.cobr_pag.pessoa_nome }}</span>
                      </v-tooltip>
                    </div>
                  </td>
                  <td align="left">
                    {{ item.cobr_pess.pessoa_nome }}
                  </td>
                  <td align="left">{{ getEmpreendimento(item) }}</td>
                </tr>

                <!-- SubGrade -->
                <tr v-if="expanded===index && item.empreendcobr_impressao_via > 0" class="data-table-impressos-fundo">
                  <td colspan="13">
                    <v-data-table class="data-table ml-10 data-table-impressos" :items="boletos" hide-default-footer single-expand>
                    <!-- :expanded.sync="expanded" -->
                      <template v-slot:header >
                        <thead >
                          <tr>
                            <th v-for="h_i in headers_impressos" :key="h_i.value" :class="h_i.class" :style="h_i.style">
                              <span class="d-flex flex-nowrap body-2 font-weight-bold">{{h_i.text}}</span>
                            </th>
                          </tr>
                        </thead>
                      </template>

                      <template #item="{ item }">
                        <tr>
                          <td style="max-width: 40px">
                            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn color="grey" dark icon v-bind="attrs" v-on="on" class="ml-n4">
                                  <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>

                              <v-list>
                                <v-list-item>
                                  <v-list-item-title @click="baixarBoleto(item)">
                                    <v-icon color="green" class="mr-2 icon-menu">mdi-cash-multiple</v-icon>Pagar Boleto
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                  <v-list-item-title @click="reimprimirBoleto(item)">
                                    <v-icon color="blue" class="mr-2 icon-menu">mdi-printer</v-icon>Reimprimir Boleto
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                  <v-list-item-title @click="excluirBoleto(item)">
                                    <v-icon color="red" class="mr-2 icon-menu">mdi-delete</v-icon>Excluir Boleto
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </td>
                          <td>{{item.empreendcobr_impressao_tipo}}</td>
                          <td>{{formatDateTime(item.empreendcobr_impressao_dt)}}</td>
                          <td>{{item.cod_empreendcobranca_impressao}}</td>
                          <td>{{item.empreendcobr_impr_valor_final}}</td>
                          <td>{{formatDate(item.empreendcobr_impr_vencto_dt)}}</td>
                          <td>{{`${item.empreendcobr_nossonumero} - ${item.empreendcobr_nossonumero_dig}`}}</td>
                        </tr>
                      </template>
                    </v-data-table>
                    <br>
                  </td>
                </tr>
              </template>
            </v-data-table>

            <!-- LEGENDA COBRANÇAS -------------------------------------------------------->
            <div id="Legenda" class="mt-2 mb-1 ml-4">
              <table class="d-flex justify-start">
                <tbody class="">
                  <tr class="text sticky-table-footer-desktop d-flex mb-2">
                    <td align="left" style="display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="mr-1 status_legend status_legend_a_vencer">
                            <v-icon color="#FFFFFF" dense class="ml-n1 mt-n1">mdi-alarm</v-icon>
                            <span class="mr-1 font-weight-thin body-2" style="color:#fff">À vencer (Qtde / Valor):</span>
                            <span class="mr-2 font-weight-medium body-1 " style="color:#fff"> {{total_aberto_parc}} / R$ {{ formatNumber(total_aberto_valor)}}</span>
                          </span>
                        </template>
                        <span>Cobranças abertas em dia</span>
                      </v-tooltip>
                    </td>

                    <td align="left" style=" background-color:#FFFFFF; display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="mr-1 status_legend status_legend_vencidas">
                            <v-icon color="#FFFFFF" dense class="ml-n1 mt-n1">mdi-alert-octagon-outline</v-icon>
                            <span class="mt-n2 mr-1 font-weight-thin body-2"  style="color:#fff">Vencidas (Qtde / Valor):</span>
                            <span class="mt-n2 mr-2 font-weight-medium body-1" style="color:#fff"> {{total_vencidas_parc}} / R$ {{ formatNumber(total_vencidas_valor)}}</span>
                          </span>
                        </template>
                        <span>Cobranças abertas com pagamento em atraso</span>
                      </v-tooltip>
                    </td>

                    <td align="left" style=" background-color:#FFFFFF; display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }"> 
                          <span v-bind="attrs" v-on="on" class="mr-1 status_legend status_legend_pagos">
                            <v-icon color="#FFFFFF" dense class="ml-n1 mt-n1">mdi-check</v-icon>
                            <span class="mt-n2 mr-1 font-weight-thin body-2"  style="color:#fff">Pagos (Qtde / Valor):</span>
                            <span class="mr-2 font-weight-medium body-1" style="color:#fff">{{total_pago_parc}} / R$ {{ formatNumber(total_pago_valor)}}</span>
                          </span>
                        </template>
                        <span>Cobranças pagas já baixadas no sistema</span>
                      </v-tooltip> 
                    </td>
                    <td v-if="total_parc_selecionado > 0" align="left" style=" background-color:#FFFFFF; display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="mr-1 status_legend status_legend_selecionados">
                            <v-icon color="#FFFFFF" dense class="ml-n1 mt-n1">mdi-check</v-icon>
                            <span class="mt-n2 mr-1 font-weight-thin body-2"  style="color:#fff">Seleção (Qtde / Aberto / Pago):</span>
                            <span class="mr-2 font-weight-medium body-1" style="color:#fff">{{total_parc_selecionado}} / R$ {{ formatNumber(total_parc_valor_selecionado)}} / R$ {{ formatNumber(total_pago_valor_selecionado)}}</span>
                          </span>
                        </template>
                        <span>Cobranças selecionadas</span>
                      </v-tooltip> 
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
            <!-- FIM RODAPE COBRANÇAS -------------------------------------------------------->

          </v-container>
        </v-col>
        <!-- Fim Grade ----------------------------------->
      </v-row>

      <!-- Paginacao ------------------------------ -->
      <div
        id="Paginacao"
        class="paginacao d-flex justify-end align-center"
        style="background-color: #FFFFFF;">
        <span
          style="color:grey; font-size:14px"
          class="mr-3">{{count_dados}}
          linhas
        </span>
        <v-pagination
          id="pagination"
          ref="pagination"
          v-model="currentPage"
          :length="store_ContasPagar.paginas"
          total-visible="5"
          @input="handlePageChange"
          aria-controls="dataTable"
          class="mt-n1"
          style="color:grey"/>
      </div>

      <!-- Dialog Excluir ----------------------- -->
      <v-dialog
        :value="dialog_excluir_confirmacao_1"
        persistent
        max-width="430"
        class="d-flex justify-center">
      <v-divider></v-divider>

      <v-card elevation="0" class="d-flex justify-center">
        <v-card-title class="text-h6">
          Tem certeza que deseja EXCLUIR ?
        </v-card-title>
        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialog_excluir_confirmacao_1 = null">
            Cancelar
          </v-btn>

          <v-btn class="btn white--text" color="primary accent-4" :loading="loading_excluir" @click="dialog_excluir_confirmacao_2 = dialog_excluir_confirmacao_1; dialog_excluir_confirmacao_1 = null;">
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog Excluir - CONFIRMAÇÃO ------------------------->
    <v-dialog
      :value="dialog_excluir_confirmacao_2"
      persistent
      max-width="430"
      class="d-flex justify-center">
      <v-divider></v-divider>

      <v-card elevation="0" class="d-flex justify-center">
        <v-card-title class="text-h6 justify-center">
          ATENÇÃO<v-icon x-large color="red">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-title class="text-h6" style="word-break: keep-all">
          Esta operação é IRREVERSÍVEL, deseja continuar?
        
          <span
            style="color:grey; font-size:14px">
            Todas as imagens e arquivos vinculados a essa cobrança serão apagados
          </span>
        </v-card-title>
        <v-card-actions class="d-flex justify-left">
          <v-btn class="btn white--text" color="error accent-4" :loading="loading_excluir" @click="excluir(dialog_excluir_confirmacao_2)">
            Excluir
          </v-btn>
          <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialog_excluir_confirmacao_2 = null">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog Excluir Selecionados - CONFIRMAÇÃO ------------------------->
    <v-dialog
      :value="dialog_excluir_selecionados_confirmacao"
      persistent
      max-width="430"
      class="d-flex justify-center">
      <v-divider></v-divider>

      <v-card elevation="0" class="d-flex justify-center">
        <v-card-title class="text-h6 justify-center">
          ATENÇÃO<v-icon x-large color="red">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-title class="text-h6">
          Esta operação é IRREVERSÍVEL, deseja continuar?
        </v-card-title>
        <v-card-actions class="d-flex justify-left">
          <v-btn class="btn white--text" color="error accent-4" :loading="loading_excluir" @click="excluirSelecionados()">
            Excluir
          </v-btn>
          <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialog_excluir_selecionados_confirmacao = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog Estornar ------------------------->
    <v-dialog
      :value="dialog_estornar_confirmacao_1"
      persistent
      max-width="430"
      class="d-flex justify-center">
      <v-divider></v-divider>

      <v-card elevation="0" class="d-flex justify-center">
        <v-card-title class="text-h6" style="word-break: keep-all">
          Tem certeza que deseja ESTORNAR o pagamento ?
        </v-card-title>
        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-btn
            id="btn_cancelar"
            ref="btn_cancelar"
            class="mr-4"
            color="primary"
            text
            @click="dialog_estornar_confirmacao_1 = null">
            Cancelar
          </v-btn>

          <v-btn
            class="btn white--text"
            color="primary accent-4"
            :loading="loading_excluir"
            @click="dialog_estornar_confirmacao_2 = dialog_estornar_confirmacao_1; dialog_estornar_confirmacao_1 = null">
            Estornar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog Estornar - CONFIRMAR ------------------------->
    <v-dialog
      :value="dialog_estornar_confirmacao_2"
      persistent
      max-width="430"
      class="d-flex justify-center">
      <v-divider></v-divider>

      <v-card elevation="0" class="d-flex justify-center">
        <v-card-title class="text-h6 justify-center">
          ATENÇÃO<v-icon x-large color="red">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-title class="text-h6" style="word-break: keep-all">
          Esta operação é IRREVERSÍVEL, deseja continuar ?
        </v-card-title>
        <v-card-actions class="d-flex justify-left">
          <v-btn class="btn white--text" color="error accent-4" :loading="loading_excluir" @click="estornar(dialog_estornar_confirmacao_2)">
            Estornar
          </v-btn>
          <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialog_estornar_confirmacao_2 = null">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog Editar/Pagar -->
    <v-container v-if="dialog_novo_editar">
      <v-dialog
        persistent
        v-model="dialog_novo_editar"
        transition="dialog-bottom-transition"
        max-width="800"
        min-width="520">
        <ContasPagarModal
          :dialog_novo_editar.sync="dialog_novo_editar"
          :dados_editar="dados_editar"
          :cedente_selecionado="cedente_selecionado"
          :dialog_lancamentos="dialog_lancamentos"
          @atualizaRegistro="atualiza_registro"
          @busca="busca(1)"

        />
      </v-dialog>
    </v-container>

    <!-- Dialog Novo -->
    <v-container v-if="dialog_novo">
      <v-dialog
        persistent
        v-model="dialog_novo"
        transition="dialog-bottom-transition"
        max-height="300"
        min-height="200"
        max-width="700"
        min-width="520">
        <ContasPagarNovoModal
          :dialog_novo.sync="dialog_novo"
          :dados_editar="dados_editar"
          :dialog_lancamentos="dialog_lancamentos"
          :cedente_selecionado="cedente_selecionado"
          :cod_imovel="cod_imovel"
          @atualizaRegistro="atualiza_registro"
          @criarNovaConta="saveConta"
          @editarConta="editConta"
          @atualizaItem="atualizaItem"
          height="900px" />
      </v-dialog>
    </v-container>

    <!-- Dialog Documentos -->
    <v-container v-if="dialog_documentos">
      <v-dialog
        persistent
        v-model="dialog_documentos"
        transition="dialog-bottom-transition"
        width="80%"
        style="overflow-x:hidden">
        <v-card elevation="0" class="pa-0" style="border-radius: 0;">
          <v-card-title 
            :style="{ 'background-color':COR_PRINCIPAL }"
            class="justify-space-between pa-1">
            <div>
              <div>
                <span class="title-page text-white pl-2">Cobrança nº {{dados_documentos.cod_empreendcobranca}}</span>
                <span class="title-page-menor text-white ml-2 mr-2"> - </span>
                <span class="title-page-menor text-white">{{dados_documentos.empreendcobr_descricao}} ( {{dados_documentos.empreendcobr_parcela_inicial}} / {{dados_documentos.empreendcobr_parcela_final}} )</span>
                <span v-if="dados_documentos.empreendcobr_situacao == 'Pago'" class="status_ativo ml-3">
                  {{ dados_documentos.empreendcobr_situacao }}
                </span>
                <span v-if="dados_documentos.empreendcobr_situacao == 'Aberto'" class="status_pendente ml-3 text-white">
                  {{ dados_documentos.empreendcobr_situacao }}
                </span>
                <span v-if="dados_documentos.empreendcobr_situacao == 'Rescindido'" class="status_cancelado ml-3 text-white">
                  {{ dados_documentos.empreendcobr_situacao }}
                </span>
                <span v-if="dados_documentos.empreendcobr_situacao == 'Inativo'" class="status_cancelado ml-3 text-white">
                  {{ dados_documentos.empreendcobr_situacao }}
                </span>
                </div>
            </div>
            <v-btn @click="dialog_documentos = false" icon dark color="#F2F6F7">
              <v-icon large class="title">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="wrapper pa-0 pagar-form" >
            <v-divider dark></v-divider>

            <div :style="{ 'background-color':COR_PRINCIPAL }">
              <!-- Cabecalho -------------------- -->
              <v-row style="width: 100%" v-if="dados_documentos.tipo == 'CONTAS A PAGAR'" class="mt-0 mx-0">
                <v-col class="pa-1">
                  <label
                    style="color:silver;font-size:14px;font-style: bold;">Favorecido:
                  </label>
                  <label
                    style="color:white;font-size:15px;"
                    class="mr-4">
                      {{ dados_documentos.cobr_pess ? dados_documentos.cobr_pess?.pessoa_nome : dados_documentos.pessoa_nome }} 
                  </label>
                  <label style="color:white;font-size:15px;" class="mr-4">|</label>
                  <label
                    style="color:silver;font-size:14px;font-style: bold;">Empreendimento:
                  </label>
                    <label
                    style="color:white;font-size:15px;"
                    class="mr-5">
                    {{ dados_documentos.cobr_pess ? dados_documentos.emp_cobr?.empreend_nome : dados_documentos.empreend_nome}}
                  </label>
                </v-col>
              </v-row>
              <v-row v-if="dados_documentos.tipo == 'CONTAS A RECEBER'" class="ml-1 mt-0">
                <v-col>
                  <label
                    style="color:silver;font-size:14px;font-style: bold;">Devedor:
                  </label>
                  <label
                    style="color:white;font-size:15px;"
                    class="mr-4">
                      {{ dados_documentos.empreend_venda.pessoa_venda.pessoa_nome }} 
                  </label>
                  <label style="color:white;font-size:15px;" class="mr-4">|</label>
                  <label
                    style="color:silver;font-size:14px;font-style: bold;">Empreendimento:
                  </label>
                    <label
                    style="color:white;font-size:15px;"
                    class="mr-5">
                    {{ dados_documentos.empreend_venda.vendas.empreend_nome}}
                  </label>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
        
        <GedDocumentos
          :dados_documentos="dados_documentos"
          :cod_empreendcobranca_aux="cod_empreendcobranca_aux"
          :paramsTipoDocumento="paramsTipoDocumento"
          :paramBusca="paramBusca"
          :statusDocumento="statusDocumento"
          @fecharModal="dialog_documentos = false" />
      </v-dialog>
    </v-container>

    <!-- Dialog Pagar em lote ----------------------- -->
    <v-container class="ma-5" v-if="dialog_contas_pagar_lote">
      <v-dialog
        v-model="dialog_contas_pagar_lote"
        persistent
        class="d-flex justify-center"
        transition="dialog-bottom-transition">
        <CarrinhoModal
          :dialog_contas_pagar_lote.sync="dialog_contas_pagar_lote"
          @contadorCarrinho="contadorCarrinho()"
          @busca="busca(1)"
        />
      </v-dialog>
    </v-container>

    <!-- Dialog Pagar em lote ----------------------- -->
    <v-container v-if="dialog_deseja_pagar_em_lote">
      <v-dialog
        :value="dialog_deseja_pagar_em_lote"
        max-width="430"
        elevation="0"
        class="d-flex justify-center">
      <v-divider></v-divider>
    
        <v-card elevation="0" class="d-flex justify-center">
          <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="justify-space-between py-2 px-3">
            <span class="white--text title-page body-1">Adicionar na cesta</span>
              <v-btn @click="dialog_deseja_pagar_em_lote = false" icon dark color="#F2F6F7">
                <v-icon large class="title">mdi-close</v-icon>
              </v-btn>
          </v-card-title>
          <v-card-title class="text-h6" style="word-break: keep-all">
            Escolha a data de Agendamento/Pagamento
          </v-card-title>
          <v-card-text class="mb-1">
            <v-row no-gutters class="d-flex justify-center">
              <v-col md="8" disabled>
                <!-- Vencimento Inicial ----------------------- -->
                <DatePicker
                  v-model="lote_agendamento_dt"
                  label="Agendamento/Pagamento"
                  placeholder="dia/mês/ano"
                  class="mr-2"
                  outlined
                  hide-details
                  backgroundColor="#FFF"
                  :style="data_picker"
                  dense />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialog_deseja_pagar_em_lote = null">
              Cancelar
            </v-btn>

            <v-btn
              :disabled="!validateDate(lote_agendamento_dt)"
              class="btn white--text" 
              color="primary accent-4"  
              @click="pagarEmLote()" 
              :loading="loading">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
            
    <!-- Dialog Limpar item cesta ----------------------- -->
    <v-container v-if="dialog_limpar_item_cesta">
      <v-dialog
        v-model="dialog_limpar_item_cesta"
        persistent
        max-width="430"
        class="d-flex justify-center">
      <v-divider></v-divider>
    
        <v-card elevation="0" class="d-flex justify-center">
          <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="justify-space-between py-2 px-3">
            <span class="white--text title-page body-1">Limpar Cobrança da Cesta</span>
              <v-btn @click="dialog_limpar_item_cesta = false" icon dark color="#F2F6F7">
                <v-icon large class="title">mdi-close</v-icon>
              </v-btn>
          </v-card-title>
          <v-card-title class="text-h6" style="word-break: keep-all">
            Tem certeza que deseja limpar esta cobrança da cesta ?
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialog_limpar_item_cesta = false; cod_empreendcobranca_aux = null">
              Cancelar
            </v-btn>

            <v-btn class="btn white--text" color="primary accent-4"  @click="limparCesta()" :loading=loading>
              Limpar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <!-- Dialog Limpar cesta ----------------------- -->
    <v-container v-if="dialog_limpar_cesta">
      <v-dialog
        v-model="dialog_limpar_cesta"
        persistent
        max-width="430"
        class="d-flex justify-center">
      <v-divider></v-divider>
    
        <v-card
          elevation="0"
          class="d-flex justify-center">
          <v-card-title
            :style="{ 'background-color':COR_PRINCIPAL }"
            class="justify-space-between py-2 px-3">
            <span
              class="white--text title-page body-1">
              Limpar a Cesta
            </span>
              <v-btn
                @click="dialog_limpar_cesta = false"
                icon
                dark
                color="#F2F6F7">
                <v-icon large class="title">mdi-close</v-icon>
              </v-btn>
          </v-card-title>
          <v-card-title class="text-h6" style="word-break: keep-all">
            Tem certeza que deseja limpar a cesta ?
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn
              id="btn_cancelar"
              ref="btn_cancelar"
              class="mr-4"
              color="primary"
              text
              @click="dialog_limpar_cesta = false; cod_empreendcobranca_aux = null">
              Cancelar
            </v-btn>

            <v-btn class="btn white--text" color="primary accent-4"  @click="limparCesta()" :loading=loading>
              Limpar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <v-snackbar
      v-model="dialog_excluir_alert"
      :timeout="4000"
      :color="dialog_excluir_message"
      :centered="false"
      :bottom="false"
      :top="true"
      max-width="400px"
      height="400px"
      :multi-line="true"
      :vertical="true"
      elevation="5"
      :light="false"
      :outlined="false"
      rounded="5"
      :shaped="false">
      {{dialog_excluir_msg}}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="dialog_excluir_alert = false" class="text-none" dark>
          <br>
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="dialog_estornar_alert"
      :timeout="4000"
      :color="dialog_estornar_message"
      :centered="false"
      :bottom="false"
      :top="true"
      max-width="400px"
      height="400px"
      :multi-line="true"
      :vertical="true"
      elevation="5"
      :light="false"
      :outlined="false"
      rounded="5"
      :shaped="false">
      {{dialog_estornar_msg}}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="dialog_estornar_alert = false" class="text-none" dark>
          <br>
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// import { API, API_CNAB } from "../../../services/API"
import { baseURL, API } from "../../../services/API";

import store_usuario from "../../../store/store_usuario";
import store_ContasPagar from "./store_ContasPagar";
import store_site from "../../../../src/store/store_site"
import store_Empreendimento from "../../Empreendimentos/store_Empreendimento";
import store_Pessoa from '../../Pessoas/store_Pessoa'
import ContasPagarModal from "./ContasPagarModal.vue";
import CarrinhoModal from "./CarrinhoModal.vue";
import ContasPagarNovoModal from "./ContasPagarNovoModal.vue";
import GedDocumentos from "../../Ged/GedDocumentos.vue";
import { formatPrice } from '../../../services/funcoes'
// import { ContasPagarGet } from "../../../services/erp";
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SECUNDARIA_2, COR_SUBTITULO, COR_BORDA, COR_BACKGROUND, COR_BACKGROUND_2, COR_BACKGROUND_TEXT, COR_PLACEHOLDER } from "../../../services/constantes";
import { maskCpfCnpj, formatDate, formatDateTime, formatNumber, validateDate } from "../../../services/funcoes";
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
import moment, { now } from 'moment';
import { saveAs } from 'file-saver';
import { AbreRelatorioExterno, Relatorio } from "../../../services/global";
import exportFromJSON from "export-from-json";
//---------------------- fim teste download excel --------

export default {
  name: "ContasPagar",

  props: ['acao', 
          'dialog_ContasPagar', 
          'itens_array_pai', 
          'parc_valor', 
          'parc_vencto', 
          'dialog_lancamentos', 
          'cedente_selecionado',
          'cod_imovel',
          'height'],

  components: {
    DatePicker,
    ContasPagarModal,
    ContasPagarNovoModal,
    GedDocumentos,
    CarrinhoModal
  },

  data() {
    return {
      user : { username : null }, 
 
      initDate                             : null,
      endDate                              : null,
      store_usuario                        : store_usuario,
      store_ContasPagar                    : store_ContasPagar,
      store_site                           : store_site,
 
      // ContasPagarGet                     : ContasPagarGet,
      isActiveVencidos                   : false,
      isActiveHoje                       : false,
      isActiveSemana                     : false,
      isActiveMes                        : false,
      validateDate                       : validateDate,
      paramsTipoDocumento                : {},
 
      empreendimentos                      : [],
      quadras                              : [],
      lotes                                : [],
      imoveis                              : [],
      boletos                              : [],
      cod_empreendcobranca_aux             : null,
      dados_documentos                     : null,
//      array_pagar_lote                     : [],
      baseURL                              : baseURL,
      lote_agendamento_dt                  : moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY').format(),
 
      maskCpfCnpj                          : maskCpfCnpj,
      formatDate                           : formatDate,
      formatDateTime                       : formatDateTime,
      formatNumber                         : formatNumber,
      formatPrice                          : formatPrice,
 
      loading                              : false,
      search                               : null,
      search_input_focus                   : false,
      searchImoveis                        : null,
 
      loading_excluir                      : false,
      loading_relatorio                    : false,
      //filtro                             : null,

      expanded                             : null,
      toolsIndex                           : null,
 
      currentPage                          : 1,
      count_dados                          : null,
      total_aberto_parc                    : 0,
      total_aberto_valor                   : 0,
      total_vencidas_parc                  : 0,
      total_vencidas_valor                 : 0,
      total_pago_parc                      : 0,
      total_pago_valor                     : 0,
      total_carrinho                       : 0,
      total_parc_selecionado               : 0,
      total_parc_valor_selecionado         : 0,
      total_pago_valor_selecionado         : 0,
 
      situacao_atual                       : null,
      statusDocumento                      : 'Aprovado',
 
      COR_PRINCIPAL                        : COR_PRINCIPAL,
      COR_SECUNDARIA                       : COR_SECUNDARIA,
      COR_SECUNDARIA_2                     : COR_SECUNDARIA_2, 
      COR_SUBTITULO                        : COR_SUBTITULO,
      COR_BORDA                            : COR_BORDA,
      accentColor                          : '#f00',
 
      dialog_excluir                       : false,
      dialog_excluir_alert                 : false,
      dialog_excluir_selecionados_confirmacao: false,
      dialog_excluir_msg                   : false,
      dialog_excluir_message               : null,
      dialog_documentos                    : false,
      dialog_deseja_pagar_em_lote          : false,
      dialog_contas_pagar_lote             : false,
      dialog_limpar_cesta                  : false,
      dialog_limpar_item_cesta             : false,
 
      dialog_excluir_confirmacao_1         : null,
      dialog_excluir_confirmacao_2         : null,
      dialog_excluir_alert_confirmacao     : false,
      dialog_excluir_msg_confirmacao       : false,
      dialog_excluir_message_confirmacao   : null,
 
      dialog_estornar                      : false,
      dialog_estornar_alert                : false,
      dialog_estornar_msg                  : false,
      dialog_estornar_message              : null,
 
      dialog_estornar_confirmacao_1        : false,
      dialog_estornar_confirmacao_2        : false,
      dialog_estornar_alert_confirmacao    : false,
      dialog_estornar_msg_confirmacao      : false,
      dialog_estornar_message_confirmacao  : null,

      dialog_pagar                         : false,
      dialog_pagar_msg                     : false,
      dialog_pagar_message                 : null,

      dialogIndiceCadastro                 : false,
      dialog_novo_editar                   : false,
      dialog_novo                          : false,

      itemSelecionado                       : null,
      selected                              : [],
      cobrancasExpanded                     : [],
      /* Título container-rounded */
      headers: [
        { text: "", value: "checkbox", style: "max-width:40px!important" },
        { text: "", value: "botoes", style: "max-width:40px!important" },
        { text: "#", value: "cod_empreendcobranca", style: "max-width:40px!important" },
        { text: "Situação", value: "empreendcobr_situacao" },
        { text: "Parcelas", value: "quant_parcelas" },
        { text: "Dt. Venc.", value: "empreendcobr_parc_dt" },
        //{ text: "Parc. Valor", value: "empreendcobr_parc_valor" },
        //{ text: "Acréscimo", value: "empreendcobr_parc_acres_valor" },
        //{ text: "Desconto", value: "empreendcobr_parc_desc_valor" },
        //{ text: "Multa/Juros", value: "empreendcobr_pagto_valor_muljur" },
        // { text: "Juros", value: "empreendcobr_pagto_valor_juros" },
        { text: "Valor", value: "empreendcobr_parc_valor_final" },
        { text: "Dt. Pagto", value: "empreendcobr_pagto_dt" },
        { text: "Valor Pagto", value: "empreendcobr_pagto_valor"},
        { text: "Descrição", value: "empreendcobr_descricao" },
        { text: "Devedor", value: "devedor" },
        { text: "Favorecido", value: "favorecido" },
        { text: "Empreendimento", value: "cod_empreendimento" },
      ],
      headers_selecionar: [
        { text: "", value: "botoes", style: "max-width:40px!important" },
        { text: "#", value: "cod_empreendcobranca", style: "max-width:40px!important" },
        { text: "Situação", value: "empreendcobr_situacao" },
        { text: "Parcelas", value: "quant_parcelas" },
        { text: "Dt. Venc.", value: "empreendcobr_parc_dt" },
        //{ text: "Parc. Valor", value: "empreendcobr_parc_valor" },
        //{ text: "Acréscimo", value: "empreendcobr_parc_acres_valor" },
        //{ text: "Desconto", value: "empreendcobr_parc_desc_valor" },
        //{ text: "Multa", value: "empreendcobr_pagto_valor_multa" },
        //{ text: "Juros", value: "empreendcobr_pagto_valor_juros" },
        { text: "Valor", value: "empreendcobr_parc_valor_final" },
        { text: "Dt. Pagto", value: "empreendcobr_pagto_dt" },
        { text: "Valor Pagto", value: "empreendcobr_pagto_valor"},
        { text: "Descrição", value: "empreendcobr_descricao" },
        { text: "Devedor", value: "devedor" },
        { text: "Favorecido", value: "favorecido" },
        { text: "Empreendimento", value: "cod_empreendimento" },
      ],
      headers_impressos: [
        { text: "",               value: "", style: "max-width:40px!important" },
        { text: "Via",            value: "empreendcobr_impressao_via",},
        { text: "Tipo",           value: "empreendcobr_impressao_tipo" },
        { text: "Data Impressão", value: "empreendcobr_impressao_dt"},
        { text: "Cód. Impressão", value: "cod_empreendcobranca_impressao" },
        { text: "Valor",          value: "empreendcobr_impr_valor_final" },
        { text: "Vencto Boleto",  value: "empreendcobr_impr_vencto_dt" },
        { text: "Nosso Número",   value: "empreendcobr_nossonumero" },
      ],
			filters: {
        empreendcobr_situacao: [],
        qtd_itens: [],
        quant_parcelas: [],
        favorecido: [],
        empreendcobr_descricao: [],
        cod_empreendimento: [],
        empreendcobr_parc_dt: [],
        empreendcobr_parc_valor: [],
        empreendcobr_parc_acres_valor: [],
        empreendcobr_parc_desc_valor: [],
        empreendcobr_pagto_dt: [],
        empreendcobr_pagto_valor: [],
			},

      /* Tabela Consulta */
      cobrancas: [],  

      /* Registro que será Incluido ou Editado */
      dados_editar : null,

      /* Opções */
      opcoes_situacao: [
                          { "id": 1, text: "Aberto", value: "Aberto" },
                          { "id": 2, text: "Pago", value: "Pago" }
                        ],

      opcoes_tipo_parcela:
                        [
                          { "id": 1, text: "Entrada", value: "ENTRADA" },
                          { "id": 2, text: "Parcela", value: "PARCELA" },
                          { "id": 3, text: "Renegociação", value: "RENEGOCIACAO" },
                          { "id": 4, text: "Residuo", value: "RESIDUO" },
                          { "id": 5, text: "Acordo", value: "ACORDO" },
                          { "id": 6, text: "Despesa", value: "DESPESA" },
                          { "id": 7, text: "Manual", value: "MANUAL" },
                        ],
                        
      opcoes_parcela:
                        [
                          { "id": 1, text: "Mensal", value: "Mensal" },
                          { "id": 2, text: "Bimestral", value: "Bimestral" },
                          { "id": 3, text: "Trimestral", value: "Trimestral" },
                          { "id": 4, text: "Semestral", value: "Semestral" },
                          { "id": 5, text: "Anual", value: "Anual" },
                        ],
                        
      opcoes_tipo_impressao:
                        [
                          { "id": 1, text: "Não Impressos", value: "Não Impressos" },
                          { "id": 2, text: "Impressos (Boletos e Antecipações)", value: "Impressos (Boletos e Antecipações)" },
                          { "id": 3, text: "Impressos (Somente Boletos)", value: "Impressos (Somente Boletos)" },
                          { "id": 4, text: "Impressos (Somente Antecipações)", value: "Impressos (Somente Antecipações)" },
                        ],
                        
      opcoes_bloqueados:
                        [
                          { "id": 1, text: "Sim", value: "Sim" },
                          { "id": 2, text: "Não", value: "Não" },
                        ],
                        
      opcoes_reajustados:
                        [
                          { "id": 1, text: "Sim", value: "Sim" },
                          { "id": 2, text: "Não", value: "Não" },
                        ],



      /* Inputs e Menus Data */
      menu    : false,
      //parc_dt_final2   : null,
      filtro_local: {},
      filtro_mesmo_valor      : true,
      filtro_mesma_data_pagto : false,
      data_picker : {
        'min-width':'150px !important',
      },
    };
  },

  mounted() {
    // console.log('itens_array_pai', this.itens_array_pai);
    // Seta os privilegios dos elementos da apícãõ inteira
    this.store_usuario.priv_seta_privilegios_aplicacao(this.$options.name);
    // console.log('store', this.store_usuario)
    this.empreendimento_privilegio_acesso();
    this.contadorCarrinho()
    if (this.acao == 'SELECIONAR') {
      this.filtroMesmoValor();
    }

    // Se estiver travado para o imovel passado no parâmetro, sempre executar 
    if (this.cod_imovel) {
      this.busca(1);
    }

  },

  watch: {

    searchImoveis(query) {
      //console.log(query, this.selectImovel);
      if (query && (!this.selectImovel || this.selectImovel.text !== query)) {
        this.querySearch(query);
      }
    },

    "filtro_local.empreendimento"(newValue, oldValue) {
      //console.log("Valor antigo:", oldValue);
      //console.log("Novo valor:", newValue);
      if (oldValue == undefined && newValue == null) {}
      else
        if (oldValue != newValue) {
          let li_CodEmpreendimento = newValue?.cod_empreendimento ?? null;
          this.getQuadras(li_CodEmpreendimento);
        }
    },

    "filtro_local.quadra"(newValue, oldValue) {
      //console.log("Valor antigo:", oldValue);
      //console.log("Novo valor:", newValue);
      if (oldValue == undefined && newValue == null) {}
      else
        if (oldValue != newValue) {
          let ls_CodEmpreendimento  = newValue?.cod_empreendimento  ?? null;
          let ls_Quadra             = newValue?.quadra              ?? null;
          this.getLotes(ls_CodEmpreendimento, ls_Quadra);
        }
    }

  },
  
  computed:{
   
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },

    isChecked(){
      const result = this.cobrancas.some(item => item.value === true && item.empreendcobr_situacao === 'Aberto');
      return result
    },

    tableHeight() {
console.log('tableHeight', window.innerHeight);
      if (this.isMobile)
        return window.innerHeight - 149 + 30 - 145;
      else
        if (this.acao == 'SELECIONAR')
          return window.innerHeight - 149 - 120;
        else if (this.height)
          return this.height;
        else
          return window.innerHeight - 149 - 92;
    },

  	filteredCobrancas() {
      return this.cobrancas?.filter((d) => {
        return Object.keys(this.filters).every((f) => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
		},
    
    cobrancasTableFooterProps () {
      return {
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right',
        'disable-items-per-page': true,
        'show-items-per-page': false,
        'items-per-page-text': '',
        'rows-per-page-items':null
      }
    }
  },

  methods: {

    busca_timeout(val) {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(async () => {
        const ls_Rel_Imoveis = await API.get(`imovel`, val);
        //console.log("this.imoveis dentro do search", ls_Rel_Imoveis)
        this.imoveis = ls_Rel_Imoveis.data.result.rows;
      }, 600);
    },
    querySearch(query) {
      let p_params = { params: {filtro: query, limit: 999999999 } }
      // let loCodEmpreendimento = null;
      // if (this.filtro_local.empreendimento) {
      //   loCodEmpreendimento = this.filtro_local.empreendimento.cod_empreendimento;
      //   p_params.params.cod_empreendimento = loCodEmpreendimento;
      //   console.log('ENTROU - this.filtro_local.empreendimento', p_params);
      // }

      this.loading = true
      this.busca_timeout(p_params);
      //console.log('entreiiiiii');
      this.loading = false
    },  

    duplicar(item){
      this.dados_editar = item
      this.dados_editar.cobr_pagto = []
      this.dados_editar.empreendcobr_pagto_dt = null
      this.dados_editar.empreendcobr_pagto_valor = null
      this.dados_editar.empreendcobr_pagto_valor_juros = null
      this.dados_editar.empreendcobr_pagto_valor_muljur = null
      this.dados_editar.empreendcobr_pagto_valor_multa = null
      this.dados_editar.empreendcobr_situacao = 'Aberto'
      let la_item = [] 
      this.dados_editar.cob_item.forEach(val => {
        if(val.empreendcobritem_tp_cobr === 'Item'){
          la_item.push(val)
        }
      })
      this.dados_editar.cob_item = la_item
      // console.log("🚀 ~ 321321", this.dados_editar.cob_item, la_item)
      // return

      this.dados_editar.acao = 'novo'
      this.dados_editar.acao2 = 'duplicar'

      this.dialog_novo = true
    },

    async imprime_recibo(value) {
      this.loading_relatorio = true;
      await AbreRelatorioExterno("Recibo de Pagamento",
                                  { Name: "cod_empreendcobranca", Value: value});
      this.loading_relatorio = false;
    },
    cobrancaJaSelecionada(cod_cobranca){
      let cobranca_selecionada = []
      this.itens_array_pai.forEach(val => {
        cobranca_selecionada.push(val.cod_empreendcobranca)
      })
    },

    fechar() {
      this.$emit('update:dialog_ContasPagar', null);
    },

    selecionar() {
      const itens = this.cobrancas.filter((d) => d.value == true).map((v) => {return v})
      this.$emit('itemSelecionado', itens);
      this.fechar();
    },

    async limparCesta(item = null){
      this.loading = true
      // let tipo_mensagem = item?.cod_empreendcobranca ? {tipo_mensagem :'individual'} : {tipo_mensagem :'cesta'}
    
      const resposta = await API.post(`/erp/contaspagar/contaspagar_limpar_cesta`, 
            {cod_empreendcobranca: this.cod_empreendcobranca_aux, tipo_mensagem : !!this.cod_empreendcobranca_aux ? 'individual' : 'cesta'}
      )
      // console.log("🚀 ~ file: ContasPagar.vue:1362 ~ limparCesta ~ resposta:", resposta)

      if (resposta?.data?.result?.status == 200) {
        if(!!this.cod_empreendcobranca_aux){
          this.cobrancas.forEach(el => {
            if(el.cod_empreendcobranca == this.cod_empreendcobranca_aux){
              el.lote_cesta_usuario = null
            }
          })
        }else{
          this.cobrancas.forEach(el => {
            el.lote_cesta_usuario = null
          })
        }
        this.contadorCarrinho()
          
        this.store_site.alert_cor       = "#00A000";
        this.store_site.alert_timeout   = 1500;
        this.store_site.alert_msg       = resposta.data.result.result.trim();
        this.store_site.alert           = true;
      
      } else {
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = resposta.data.result.result.trim();
        this.store_site.alert           = true;
      }

      // const resposta = await API.put(`/erp/contaspagar/contaspagar_teste`, param : {});
      // const resposta = await API.put(`/erp/contaspagar/contaspagar_limpar_cesta${param}`);
      this.dialog_limpar_cesta = false
      this.dialog_limpar_item_cesta = false
      this.cod_empreendcobranca_aux = null
      this.loading = false
    },

    async mostrarCobrancasLote(){
      
      this.dialog_contas_pagar_lote = true
    },

    async contadorCarrinho(){
      const {data} = await API.get(`/erp/contaspagar_contador_carrinho_pagar_lote`);
      this.total_carrinho = data.result.data
      if(this.total_carrinho == 0){
        this.total_carrinho = "0"
      }
    },

    async pagarEmLote(){
      this.loading = true
      const idsList = []

      try {
        var cobranca_paga = false
        this.cobrancas.forEach(item => {
          if (item.value && item.empreendcobr_situacao === 'Pago') {
            cobranca_paga = true
          }
          if (item.value && item.empreendcobr_situacao === 'Aberto') {
            idsList.push(item.cod_empreendcobranca)
          }
        })

        if(cobranca_paga){
          cobranca_paga = false
          this.store_site.alert_cor       = "#FF0000";
          this.store_site.alert_timeout   = 10000;
          this.store_site.alert_msg       = 'Não é possível pagar cobranças que já tenham sido pagas!'
          this.store_site.alert           = true;
          return
        }

        const resposta = await API.put(`/erp/contaspagar/carrinho_pagar_lote/${JSON.stringify(idsList)}`, 
          { params : { lote_agendamento_dt : moment(this.lote_agendamento_dt).format() } }
        );
        if (resposta?.data?.result?.status == 200) {
          this.total_carrinho = resposta.data.result.data || 0
          this.cobrancas.forEach(el =>{
            if(idsList.includes(el.cod_empreendcobranca)){
              // console.log('achei',el.cod_empreendcobranca)
              el.lote_cesta_usuario = 1
            }
          })
          
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = resposta.data.result.result.trim();
          this.store_site.alert           = true;
        
        } else {
          this.store_site.alert_cor       = "#FF0000";
          this.store_site.alert_timeout   = 10000;
          this.store_site.alert_msg       = resposta.data.result.result.trim();
          this.store_site.alert           = true;
        }
      }
      catch{

      }
      finally{
        this.dialog_deseja_pagar_em_lote = false
        this.loading = false
      }
    },

    documentos(item) {
      this.dados_documentos = {...item}
      this.dados_documentos.tipo = 'CONTAS A PAGAR';
      this.paramsTipoDocumento = {tipo_cadastro: 'cobranca', tipo_cadastro_descricao: "Documentos Pagar/Receber"}
      this.paramBusca = {cod_empreendcobranca : item.cod_empreendcobranca}
      this.statusDocumento = 'Aprovado'
      this.cod_empreendcobranca_aux = item.cod_empreendcobranca
      this.dialog_documentos = true
    },

    atualizaItem(dados){
      this.dados_editar.cob_item = [];
      this.dados_editar.cob_item.push(dados);
    },

    async saveConta (conta) {
      conta?.cob_item.forEach((element, index) => {
        conta.cob_item[index].cod_centrocusto = element.centrocusto?.cod_centrocusto
        conta.cob_item[index].cod_planoconta = element.planoconta?.cod_planoconta
      });

      await this.busca(1);

      this.dialog_novo = false
    },

    async editConta (item) {
      item?.cob_item.forEach((element, index) => {
        item.cob_item[index].cod_centrocusto = element?.centrocusto?.cod_centrocusto
        item.cob_item[index].cod_planoconta = element?.planoconta?.cod_planoconta
      });

      const index = this.cobrancas.findIndex(cobranca => {
        return item.cod_empreendcobranca === cobranca.cod_empreendcobranca
      })

      // this.$set(this.cobrancas, index, { ...item, cob_item: [ ...item.cob_item ], cobr_dev: {...item.cobr_dev}, cobr_pess: {...item.cobr_pess} })

      this.dialog_novo = false;
      await this.busca(1);
    },

    getEmpreendimento (item) {
      // console.log('empreendimento', item, this.cobrancas)
      return item.emp_cobr?.empreend_nome || store_Empreendimento.dados?.find(emp => emp.cod_empreendimento == item.cod_empreendimento)?.empreend_nome || ''
    },

    // getNomeFavorecido (item) {
    //   if (!item?.cobr_pess?.pessoa_nome) {
    //     return store_Pessoa.dados.find(({ cod_pessoa }) => cod_pessoa === item.cod_pessoa).pessoa_nome
    //   }

    //   return item.cobr_pess.pessoa_nome
    // },

    // Busca todas as quadras do empreendimento selecionado
    async getQuadras(cod_empreendimento) {
      const lo_Param = {cod_empreendimento: cod_empreendimento}
      const resposta = await API.get(`/imovel_quadras_por_empreendimento`, {params: lo_Param});
      this.quadras = [];
      this.filtro_local.quadra = null;
      this.lotes = [];
      this.filtro_local.lote = null;
      if (resposta.status == 200) {
        this.quadras = resposta.data.result;
      }
    },

    // Busca todos os lotes da quadra selecionada
    async getLotes(cod_empreendimento, quadra) {
      const lo_Param = {cod_empreendimento: cod_empreendimento, quadra: quadra}
      const resposta = await API.get(`/imovel_lotes_por_quadra`, {params: lo_Param});
      this.lotes = [];
      this.filtro_local.lote = null;
      if (resposta.status == 200) {
        this.lotes = resposta.data.result;
      }
    },

    async excluirSelecionados () {
      const idsList = []

      try {
        this.cobrancas.forEach(item => {
          if (item.value && item.empreendcobr_situacao === 'Aberto') {
            idsList.push(item.cod_empreendcobranca)
          }
        })
        
        const response = await store_ContasPagar.ContasPagarExcluir(idsList)

        if (response.data.result.status == 200) {
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = response.data.result.result.trim();
          this.store_site.alert           = true;
        } else {
          // Mensagem de exclusão não realizada
          this.store_site.alert_cor       = "#FF0000";
          this.store_site.alert_timeout   = 10000;
          this.store_site.alert_msg       = response.data.result.result.trim();
          this.store_site.alert           = true;
        }

        if (response) {
          this.cobrancas = this.cobrancas.filter(item => {
            if (!idsList.includes(item.cod_empreendcobranca)) {
              return item
            }
          })
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.dialog_excluir_selecionados_confirmacao = false
      }
    },

    changeVencimento(val){
      this.isActiveVencidos = false
      this.isActiveHoje = false
      this.isActiveMes = false
      this.isActiveSemana = false
    },

    vencendo(dias, vmodel){
      this.changeVencimento();
      if (vmodel === 'isActiveVencidos') {
        this.isActiveVencidos = true
      }
      if (vmodel === 'isActiveHoje') {
        this.isActiveHoje = true
      }
      else if (vmodel === 'isActiveSemana') {
        this.isActiveSemana = true
      }
      else if (vmodel === 'isActiveMes') {
        this.isActiveMes = true
      }
      
      this.filtro_local.parc_dt_inicial = moment().format();
      if (vmodel === 'isActiveVencidos') {
        this.isActiveVencidos = true
        this.filtro_local.parc_dt_inicial = null;
      }
      this.filtro_local.situacao = "Aberto";
      let data = moment(); // cria um objeto moment com a data atual
      data = data.add(dias, 'days'); // adiciona dias ao objeto moment
      this.filtro_local.parc_dt_final = data.format(); // exibe a nova data formatada
    },

    filtroMesmoValor() {
      if (this.filtro_mesmo_valor) {
        this.filtro_local.parc_valor_inicial = Math.abs(this.parc_valor);
        this.filtro_local.parc_valor_final   = Math.abs(this.parc_valor);
      }
      else {
        this.filtro_local.parc_valor_inicial = null;
        this.filtro_local.parc_valor_final   = null;
      }
      this.busca(1);
    },

    filtroMesmaDataPagto() {
      if (this.filtro_mesma_data_pagto) {
        this.filtro_local.parc_dt_pagto_inicial = this.dialog_lancamentos?.data;
        this.filtro_local.parc_dt_pagto_final   = this.dialog_lancamentos?.data;
      }
      else {
        this.filtro_local.parc_dt_pagto_inicial = null;
        this.filtro_local.parc_dt_pagto_final   = null;
      }
      this.busca(1);
    },
   
    expandRow(index, item) {
      if (item.empreendcobr_impressao_via > 0) {
        if (this.expanded != index) {
          this.boletos = [];
          this.expanded = index;
          this.impressos(item.cod_empreendcobranca)
        }
        else {
          this.boletos = [];
          this.expanded = null;
        }
      }
      else {
        this.boletos = [];
        this.expanded = null;
      }
    },

    async export_table(dados, tipo, nome_arquivo) {
      //dados.forEach( e => ({value: "sssssss", ...e}))
      const lo_Obj = this.cobrancas.map((e) => (
        {  
          documento       : e.cod_empreendcobranca,
          situacao        : e.empreendcobr_situacao,
          parcelas        : String(`${e.empreendcobr_parcela_inicial} -- ${e.empreendcobr_parcela_final}`),
          favorecido      : e.cobr_pess?.pessoa_nome,
          descricao       : e.empreendcobr_descricao,
          vencimento      : formatDate(e.empreendcobr_parc_dt),
          parc_valor      : formatNumber(e.empreendcobr_parc_valor) || 0,
          acrescimo       : formatNumber(e.empreendcobr_parc_acres_valor) || 0,
          desconto        : formatNumber(e.empreendcobr_parc_desc_valor) || 0,
          multa           : formatNumber(e.empreendcobr_pagto_valor_multa) || 0,
          juros           : formatNumber(e.empreendcobr_pagto_valor_juros) || 0,
          valor_final     : formatNumber(e.empreendcobr_parc_valor_final) || 0,
          dt_pagto        : formatDate(e.empreendcobr_pagto_dt) || '',
          empreendimento  : e.emp_cobr?.empreend_nome
                                                
        }
      ));
      // console.log("lo_Obj",lo_Obj)
      dados = lo_Obj;
      const data = dados;
      const fileName = nome_arquivo;
      let exportType;
      if (tipo == 'xls')
        exportType = exportFromJSON.types.xls;
      if (tipo == 'csv')
        exportType = exportFromJSON.types.csv;
      if (tipo == 'pdf')
        exportType = exportFromJSON.types.txt;
      if (data)
        exportFromJSON({ data, fileName, exportType });
    },

    limpa_todos() {
      for(let prob in this.filtro_local){
        this.filtro_local[prob] = null
      }
      this.isActiveVencidos = false
      this.isActiveHoje     = false
      this.isActiveMes      = false
      this.isActiveSemana   = false
    },

    async busca(page) {
      this.loading = true;

      await this.contadorCarrinho()

      if (page)
        this.currentPage = page;
      // this.loading  = true;
      this.menu     = false;
      this.boletos  = [];
      this.expanded = null;
      this.store_ContasPagar.filtro = {...this.filtro_local};
    
      if (this.store_ContasPagar.filtro.cliente_nome) {
        //console.log("this.store_ContasPagar.filtro.filtro", this.store_ContasPagar.filtro.cliente_nome)
        this.store_ContasPagar.filtro.cliente_nome = this.store_ContasPagar.filtro.cliente_nome.trim()
      }
      var lo_params = { page: page, ...this.store_ContasPagar.filtro };
      // Limpando o objeto Empreendimento para enviar a API e deixando somente os codigos
      lo_params.cod_empreendimento   = null;
      if (this.store_ContasPagar.filtro.empreendimento) {
        lo_params.cod_empreendimento = this.store_ContasPagar.filtro.empreendimento.cod_empreendimento;
        //lo_params.empreendimento     = null;
      }
      lo_params.parc_valor_inicial        ? lo_params.parc_valor_inicial        = Number(lo_params.parc_valor_inicial) : null;
      lo_params.parc_valor_final          ? lo_params.parc_valor_final          = Number(lo_params.parc_valor_final) : null;
      lo_params.parc_valor_pagto_inicial  ? lo_params.parc_valor_pagto_inicial  = Number(lo_params.parc_valor_pagto_inicial) : null;
      lo_params.parc_valor_pagto_final    ? lo_params.parc_valor_pagto_final    = Number(lo_params.parc_valor_pagto_final) : null;

      // se for da conciliação não trazercobranças conciliadas
      if(!!this.cedente_selecionado){
        lo_params.conciliado = true
      }

      // Se estiver travado para o imovel passado no parâmetro, sempre executar 
      if (this.cod_imovel) {
        let p_params = { params: {cod_imovel: this.cod_imovel } }
        const ls_Rel_Imoveis = await API.get(`imovel`, p_params);
        this.imoveis = ls_Rel_Imoveis.data.result.rows;
        this.store_ContasPagar.filtro.imovel = this.imoveis[0];
        console.log('____this.imoveis ', this.imoveis)
      }

      // Limpando o objeto Imovel Avulso para enviar a API e deixando somente os codigos
      lo_params.cod_imovel   = null;
      if (this.store_ContasPagar.filtro.imovel) {
        lo_params.cod_imovel = this.store_ContasPagar.filtro.imovel.cod_imovel;
        //lo_params.imovel     = null;
        console.log('================', lo_params)
      }

      let la_JSON;
      la_JSON = await API.get("erp/contaspagar", { params: { ...lo_params} });
      var lo_res = la_JSON.data.result

      this.total_aberto_parc    = 0;
      this.total_aberto_valor   = 0;
      this.total_vencidas_parc  = 0,
      this.total_vencidas_valor = 0;
      this.total_pago_parc      = 0;
      this.total_pago_valor     = 0;
      this.total_parc_selecionado               = 0;
      this.total_parc_valor_selecionado         = 0;
      this.total_pago_valor_selecionado         = 0;
      
      if (lo_res?.rows?.length)  {
        this.count_dados = lo_res.rows.length;
        this.store_ContasPagar.paginas = Math.ceil(this.count_dados / 20)
        this.cobrancas = lo_res.rows.map(v => ({value: false, 
                                                docs: v.cobr_gedvinculo.reduce((acc, item) => {
                                                                                          const { tipo_documento } = item;
                                                                                          if (!acc[tipo_documento]) {
                                                                                            acc[tipo_documento] = 0; // Inicializa a contagem se a chave ainda não existe
                                                                                          }
                                                                                          acc[tipo_documento] += 1; // Incrementa a contagem
                                                                                          return acc;
                                                                                        }, {}),
                                                ...v,}));
        await this.rodape()
      }
      else
        this.cobrancas = [];

      this.loading = false;

      // Posiciona o Scrool no começo da tabela 
      this.$vuetify.goTo('.data-table tbody tr',
                          { offset: 80,
                            container: '.v-data-table__wrapper'
                          }
                        )
    },

    async rodape(){
      // Somando valores do rodapé
      this.total_aberto_parc      = 0;
      this.total_aberto_valor     = 0;
      this.total_vencidas_parc    = 0;
      this.total_vencidas_valor   = 0;
      this.total_pago_parc        = 0;
      this.total_pago_valor       = 0;
      this.total_parc_selecionado = 0;
      this.total_parc_valor_selecionado = 0;
      this.total_pago_valor_selecionado = 0;
      this.situacao_atual         = null;
      this.cobrancas.forEach(elem => {
        
        this.situacao_atual       = elem.empreendcobr_situacao

        if(moment().format('YYYY-MM-DD') <= moment(elem.empreendcobr_parc_dt).format('YYYY-MM-DD') && 
            elem.empreendcobr_pagto_dt == null && 
            elem.empreendcobr_situacao === 'Aberto'){
          this.total_aberto_parc    += elem.empreendcobr_situacao === 'Aberto' ? 1 : 0;
          this.total_aberto_valor   += elem.empreendcobr_situacao === 'Aberto' ? elem.empreendcobr_parc_valor : 0;
        }
        else if(moment().format('YYYY-MM-DD') > moment(elem.empreendcobr_parc_dt).format('YYYY-MM-DD') && 
                elem.empreendcobr_pagto_dt == null && 
                elem.empreendcobr_situacao === 'Aberto'){
          this.total_vencidas_parc  += elem.empreendcobr_situacao === 'Aberto' ? 1 : 0;
          this.total_vencidas_valor += elem.empreendcobr_situacao === 'Aberto' ? elem.empreendcobr_parc_valor : 0;
        }
        this.total_pago_parc        += elem.empreendcobr_situacao === 'Pago'   ? 1 : 0;
        this.total_pago_valor       += elem.empreendcobr_situacao === 'Pago'   ? elem.empreendcobr_pagto_valor : 0;
  
        // Totais para os Selecionados
        this.total_parc_selecionado       += (elem.value) ? 1 : 0;
        this.total_parc_valor_selecionado += (elem.value && elem.empreendcobr_situacao === 'Aberto') ? elem.empreendcobr_parc_valor_final : 0;
        this.total_pago_valor_selecionado += (elem.value && elem.empreendcobr_situacao === 'Pago') ? elem.empreendcobr_pagto_valor : 0;
      });
    },

    selecionaTodos (e) {
      this.$nextTick(() => {
        this.cobrancas.map((v) => {v.value = e});
        this.rodape();
      })
    },

    // Filtra os empreendimentos que o usuário possui acesso
    async empreendimento_privilegio_acesso() {
      const resp = await API.get(`empreendimento_privilegio_acesso`);
      if (resp) {
        var la_Resp = resp.data.result;
        this.empreendimentos = la_Resp;
      }
    },

    // Filtra os imóveis ou Unidades do empreendimento selecionado
    // Traz todos os imóveis quando não filtra empreendimento
    async imoveisGet(cod_empreendimento) {
      const resp = await API.get(`imovel`, {params: {cod_empreendimento : cod_empreendimento, limit: 999999999}});
      if (resp) {
        var la_Resp = resp.data.result;
        this.imoveis = la_Resp;        
      }
    },

    // Função para enviar dados para o filho ContasPagarModal.vue e realizar a edição
    async editar(item) {
      this.dados_editar = item
      this.dados_editar.acao = 'editar'
      this.dialog_novo = true
    },

    // Função que recebe alterações do filho ContasPagarModal.vue para atualizar a grade
    async atualiza_registro(p_JSON) {
      let lo_Retorno = await this.cobrancas.find((val) => val.cod_empreendcobranca == p_JSON.cod_empreendcobranca);
      if (lo_Retorno) {
        Object.keys(p_JSON).forEach(function (key) {
          lo_Retorno[key] = p_JSON[key];
        });  
      }
      // atualizar o rodapé
      await this.rodape()
    },

    // função para estornar
    async estornar(item){
      const lo_JSON = { cod_empreendcobranca                : item.cod_empreendcobranca,
                        cod_devedor_pagador                 : item.cod_devedor_referencia,
                        empreendcobr_situacao               : "Aberto",
                        empreendcobr_parc_valor_final       : item.empreendcobr_parc_valor,
                        empreendcobr_pagto_valor            : null,
                        empreendcobr_parc_desc_valor        : null,
                        empreendcobr_parc_acres_valor       : null,
                        empreendcobr_pagto_dt               : null,
                        empreendcobr_pagto_valor_juros      : null,
                        empreendcobr_pagto_valor_multa      : null,
                        empreendcobr_pagto_valor_muljur     : null,
                        empreendcobr_pagto_dt               : null,
                        empreendcobr_parc_desc_obs          : null,
                        empreendcobr_parc_acres_obs         : null,
                        lote_cesta_usuario                  : null,
                        lote_cesta_data_hora                : null,
                        lote_cesta_numero_gerado            : null,
                        empreendcobr_nf                     : null,
                      }
      this.dialog_estornar_confirmacao_2 = null;
      let ls_Res = null
      ls_Res = await this.store_ContasPagar.ContasPagarEstornar(lo_JSON);
      if (ls_Res.status == 200) {
        // Mensagem de estorno confirmado
        this.store_site.alert_cor       = "#00A000";
        this.store_site.alert_timeout   = 1500;
        this.store_site.alert_msg       = ls_Res.data.result.result.trim();
        this.store_site.alert           = true;
        let lo_Retorno = await this.cobrancas.find((val) => val.cod_empreendcobranca == lo_JSON.cod_empreendcobranca);
            
        if (lo_Retorno) {
          lo_Retorno.empreendcobr_situacao               = lo_JSON.empreendcobr_situacao;
          lo_Retorno.empreendcobr_pagto_valor            = lo_JSON.empreendcobr_pagto_valor;
          lo_Retorno.empreendcobr_pagto_dt               = lo_JSON.empreendcobr_pagto_dt;
          lo_Retorno.empreendcobr_pagto_valor_juros      = lo_JSON.empreendcobr_pagto_valor_juros;
          lo_Retorno.empreendcobr_pagto_valor_multa      = lo_JSON.empreendcobr_pagto_valor_multa;
          // lo_Retorno.empreendcobr_parc_valor_final       = lo_JSON.empreendcobr_parc_valor_final;
          lo_Retorno.empreendcobr_pagto_dt               = lo_JSON.empreendcobr_pagto_dt;
          lo_Retorno.empreendcobr_parc_desc_valor        = lo_JSON.empreendcobr_parc_desc_valor
          lo_Retorno.empreendcobr_parc_acres_valor       = lo_JSON.empreendcobr_parc_acres_valor
          lo_Retorno.empreendcobr_parc_desc_obs          = lo_JSON.empreendcobr_parc_desc_obs
          lo_Retorno.empreendcobr_parc_acres_obs         = lo_JSON.empreendcobr_parc_acres_obs
          lo_Retorno.empreendcobr_nf                     = lo_JSON.empreendcobr_nf
          lo_Retorno.cobr_pag                            = item.cobr_dev
          lo_Retorno.cod_devedor_pagador                = lo_JSON.cod_devedor_pagador
          lo_Retorno.pagador                            = lo_JSON.devedor
          lo_Retorno.cobr_pagto = []

          await this.busca(1)
          // atualizar o rodapé
          await this.rodape()   
        }             
      } else {
        // Mensagem de estorno não realizado
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = ls_Res.data.errors.trim();
        this.store_site.alert           = true;
      }
    },

    async excluir(item){
      let ls_Res = null
      ls_Res = await store_ContasPagar.ContasPagarExcluir(item);                         
      this.dialog_excluir_confirmacao_2 = null;

      if (ls_Res.status == 200) {
        // Mensagem de exclusão confirmada
        this.store_site.alert_cor       = "#00A000";
        this.store_site.alert_timeout   = 1500;
        this.store_site.alert_msg       = ls_Res.data.result.result.trim();
        this.store_site.alert           = true;
        // Atualizando a grade sem chamar API
        let i = this.cobrancas.findIndex(data => data.cod_empreendcobranca == item);
        if (i !== -1) {
          this.cobrancas.splice(i, 1);
        }
        //this.cobrancas.splice((val) => val.cod_empreendcobranca == item, 1);
        await this.rodape()
      } else {
        // Mensagem de exclusão não realizada
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = ls_Res.data.errors.trim();
        this.store_site.alert           = true;
      }

    },

    async novo(){
      this.dados_editar                                     = [];
      this.dados_editar.empreend_venda                      = {};
      this.dados_editar.empreend_venda.vendas               = {};
      this.dados_editar.empreend_venda.pessoa_venda         = {};
      this.dados_editar.empreend_venda.empreendvenda_imovel = {};
      this.dados_editar.acao = 'novo'
      this.dialog_novo                                      = true;
    },

    async impressos(item){
      const ls_Res = await this.store_ContasPagar.ContasPagarImpressos(item); 
      this.boletos = ls_Res.data.result
    },

    // Função para enviar dados para o filho ContasPagarModal.vue pagamento/baixa 
    async pagar(item){
      this.dados_editar = this.cobrancas.find(cobranca => cobranca.cod_empreendcobranca === item.cod_empreendcobranca)
      this.dados_editar.devedor = this.dados_editar.cobr_dev.pessoa_nome
      this.dados_editar.pagador = this.dados_editar.cobr_pag.pessoa_nome

      this.dialog_novo_editar = true
    },

    // Função para enviar dados para o filho ContasPagarModal.vue e realizar vizualização
    async visualizar(item){
      this.dados_editar = [];
      const resp = await ContasPagarGet({cod_empreendcobranca: item.cod_empreendcobranca});
      if (resp) {
        this.dados_editar = {...resp};
        //atribui uma ação ao JSON para usar no filho
        this.dados_editar.acao = 'VISUALIZAR';
        //this.dados_editar.empreendcobr_pagto_dt = moment().locale('pt-br').format('L');
        this.dados_editar.empreendcobr_obs = !this.dados_editar.empreendcobr_obs ? ' ' : this.dados_editar.empreendcobr_obs;
        this.dados_editar.empreendcobr_parc_acres_obs = !this.dados_editar.empreendcobr_parc_acres_obs ? ' ' : this.dados_editar.empreendcobr_parc_acres_obs;
        this.dados_editar.empreendcobr_parc_desc_obs = !this.dados_editarempreendcobr_parc_desc_obs ? ' ' : this.dados_editar.empreendcobr_parc_desc_obs;
        this.dialog_novo_editar = true;    
      }
    },

    async cnab() {
      const lo_JSON = { "type"  : "pdf",
                        "data"  : [ { "valor":5.0,
                                      "cedente":"Kivanio Barbosa",
                                      "documento_cedente":"12345678912",
                                      "sacado":"Claudio Pozzebom",
                                      "sacado_documento":"12345678900",
                                      "agencia":"0810",
                                      "conta_corrente":"53678",
                                      "convenio":12387,
                                      "nosso_numero":"12345678",
                                      "bank":"itau"
                                    },
                                    { "valor": 10.00,
                                      "cedente": "PREFEITURA MUNICIPAL DE VILHENA",
                                      "documento_cedente": "04092706000181",
                                      "sacado": "João Paulo Barbosa",
                                      "sacado_documento": "77777777777",
                                      "agencia": "1825",
                                      "conta_corrente": "0000528",
                                      "convenio": "245274",
                                      "nosso_numero": "000000000000001",
                                      "bank":"caixa"
                                    }
                                  ]
                      }

      // const resp = await API_CNAB.post(`/boleto/multi`, JSON.stringify(lo_JSON));

      // este abaixo deu CERTO
      // const resp = await API_CNAB.get('boleto/nosso_numero?bank=itau&data=%7B"valor":0.0,"cedente":"Kivanio%20Barbosa","documento_cedente":"12345678912","sacado":"Claudio%20Pozzebom","sacado_documento":"12345678900","agencia":"0810","conta_corrente":"53678","convenio":12387,"numero_documento":"12345678"%7D');
    
      // axios.post('http://localhost:9292/api', lo_JSON)
      //   .then(response => console.log(response))
      //   .catch(error => console.log(error)) 
    },

    // Acoes de Boletos
    async baixarBoleto(item) {
      let ls_Res = null
      ls_Res = await this.store_ContasPagar.ContasPagarImpressosBaixar(item); 
      //this.dialog_excluir_confirmacao_2 = null;
      if (ls_Res.status == 200) {
            // Mensagem de exclusão confirmada
            this.store_site.alert_cor       = "#00A000";
            this.store_site.alert_timeout   = 1500;
            this.store_site.alert_msg       = ls_Res.data.result.result.trim();
            this.store_site.alert           = true;
            // Atualizando a grade sem chamar API
            await this.cobrancas.splice((val) => val.cod_empreendcobranca == item, 1);

      } else {
            // Mensagem de exclusão não realizada
            this.store_site.alert_cor       = "#FF0000";
            this.store_site.alert_timeout   = 10000;
            this.store_site.alert_msg       = ls_Res.data.errors.trim();
            this.store_site.alert           = true;
          }
    },

    async reimprimirBoleto(item) {
      // const lo_Item = {
      //               "banco" : "Sicoob",
      //               "boletos"	: [
      //                     {
      //                       "pagador": {
      //                         "nome": "José Bonifácio de Andradaaaaaa",
      //                         "RegistroNacional": "12345678",
      //                         "endereco": {
      //                           "logradouro": "Rua Pedro Lessa, 15",
      //                           "bairro": "Centro",
      //                           "cidade": "Rio de Janeiro",
      //                           "estadoUF": "RJ",
      //                           "cep": "20030-030"
      //                         }
      //                       },
      //                       "instrucoes": ["Após o vencimento Mora dia R$ 1,59", "Após o vencimento, multa de 2%"],
      //                       "beneficiario": {
      //                         "nome": "Empresa Fictícia LTDA",
      //                         "cnpj": "03411967000154",
      //                         "dadosBancarios": {
      //                           "carteira": "1",
      //                           "agencia": "3214",
      //                           "agenciaDigito": "0",
      //                           "conta": "4995163",
      //                           "contaDigito": "0",
      //                           "nossoNumero": "0000001",
      //                         "nossoNumeroDigito": "0"
      //                         },
      //                         "endereco": {
      //                           "logradouro": "Rua Pedro Lessa, 15",
      //                           "bairro": "Centro",
      //                           "cidade": "Rio de Janeiro",
      //                           "estadoUF": "RJ",
      //                           "cep": "20030-030"
      //                         }
      //                       },
      //                       "boleto": {
      //                         "numeroDocumento": "1001",
      //                         "especieDocumento": "DM",
      //                         "valor": 110.00,
      //                         "datas": {
      //                           "vencimento": "02-04-2020",
      //                           "processamento": "02-04-2019",
      //                           "documentos": "02-04-2019"
      //                         }
      //                       }
      //                     }
      //               ]
      //             }      
      //console.log("item", item)
      
      let la_Items = [];

      //////////////////////////////////////////////////////////////////////////////////////////
      // Relatorio Stimulsoft (BACKEND) -----------------------
      // for (var i = 0; i < 1; i++) {
      //   la_Items.push(parseInt(item.cod_empreendcobranca_impressao) + i);
      // }
      // const lo_Item =  [{Name: "COD_IMPRESSAO", Value : la_Items}]
      // let data = {
      //   nome        : "Financeiro.Boleto.Sicoob",
      //   tipo        : "pdf",
      //   parametros  : lo_Item,
      //   //mail       : { to      : ['fernando@viscom.com.br'],
      //   //               subject : 'Boleto Automático Viscom (teste)'},
      // };
      // this.loading_relatorio = true;
      // let ls_Resp = await Relatorio(data);
      // this.loading_relatorio = false;

      //////////////////////////////////////////////////////////////////////////////////////////
      // Relatorio Stimulsoft (FRONTEND) -----------------------
      for (var i = 0; i < 1; i++) {
        la_Items.push(parseInt(item.cod_empreendcobranca_impressao) + i);
        const resp = await API.get("/erp/contasPagar/contasPagar_reimprimirboletos",
                                  { params: {
                                      cod_empreendcobranca_impressao: parseInt(item.cod_empreendcobranca_impressao) + i
                                    }
                                  }
                                );
      }
      this.loading_relatorio = true;
      await AbreRelatorioExterno("Financeiro.Boleto." + item.banco_cod_compensacao,
                                  { Name: "COD_IMPRESSAO", Value: la_Items});
      this.loading_relatorio = false;

      //////////////////////////////////////////////////////////////////////////////////////////
      // Relatorio pdfME --------------------------
      // for (var i = 0; i < 200; i++) {
      //   la_Items.push(parseInt(item.cod_empreendcobranca_impressao) + i);
      // // la_Items.push( {
      // //                   "name": "Parra Imobiliária Ltda",
      // //                   "age": "4 years",
      // //                   "sex": "Male",
      // //                   "weight": "33 pounds",
      // //                   "breed": "Mutt",
      // //                   "vencimento1": formatDate(item.empreendcobr_impr_vencto_dt),
      // //                   "vencimento2": formatDate(item.empreendcobr_impr_vencto_dt),
      // //                   "valor1": formatNumber(item.empreendcobr_impr_valor),
      // //                   "valor2": formatNumber(item.empreendcobr_impr_valor),
      // //                   "cod_bar": item.empreendcobr_codigobarra,
      // //                   "linha_digitavel1": item.empreendcobr_linhadigitavel,
      // //                   "linha_digitavel2": item.empreendcobr_linhadigitavel,
      // //                   "nosso_numero1": item.empreendcobr_nossonumero,
      // //                   "nosso_numero2": item.empreendcobr_nossonumero,
      // //                 }
      // //               );
      // }
      // const ls_Items = la_Items.join(',')
      // console.log('ls_Items', ls_Items);
      // const resp = await API.get("/erp/contasPagar/contasPagar_reimprimirboletos",
      //                             { params: {
      //                                 cod_empreendcobranca_impressao: ls_Items
      //                               }
      //                             }
      //                           );
      // if (resp.data && resp.data.result) {
      //   let data = {
      //     tipo: "pdfME",
      //     inputs: resp.data.result,
      //     destino: "EMAIL NAO",
      //   };
      //   this.loading_relatorio = true;
      //   await Relatorio(data);
      //   this.loading_relatorio = false;
      //   console.log("resp.data.result", resp.data.result);
      // }

      //////////////////////////////////////////////////////////////////////////////////////////
    },

    async excluirBoleto(item) {
      let ls_Res = null
      // console.log('chamou function Front')
      ls_Res = await this.store_ContasPagar.ContasPagarImpressosExcluir(item); 
      // console.log('chamou function Front', ls_Res)                        
      //this.dialog_excluir_confirmacao_2 = null;
      if (ls_Res.status == 200) {
            // Mensagem de exclusão confirmada
            this.store_site.alert_cor       = "#00A000";
            this.store_site.alert_timeout   = 1500;
            this.store_site.alert_msg       = ls_Res.data.result.result.trim();
            this.store_site.alert           = true;
            // Atualizando a grade sem chamar API
            await this.cobrancas.splice((val) => val.cod_empreendcobranca == item, 1);

      } else {
            // Mensagem de exclusão não realizada
            this.store_site.alert_cor       = "#FF0000";
            this.store_site.alert_timeout   = 10000;
            this.store_site.alert_msg       = ls_Res.data.errors.trim();
            this.store_site.alert           = true;
          }
    },

    // Retorna os codigos das cobrancas selecionadas
    selecionados() {
			return this.cobrancas.filter((d) => d.value == true).map((v) => {return v.cod_empreendcobranca})
		},

    onBlur() {
      this.search_input_focus = false;
    },

    onFocus() {
      this.search_input_focus = true;
    },

    abriuConsulta(val) {
      // console.log( 'abriuConsulta', this.dados_editar)
      if (val) {
        this.$nextTick(() => {
          this.filtro_local = {...this.store_ContasPagar.filtro};
        });
      }
    },

    handlePageChange(value) {
      this.currentPage = value;

      // Posiciona o Scrool no começo da tabela 
      this.$vuetify.goTo('.data-table tbody tr',
                          { offset: 60,
                            container: '.v-data-table__wrapper'
                          }
                        )
      
    },
  },


}  

</script>

<style scoped>

  .title-page {
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0px;
  }

  .text-white {
    color: #FFFFFF;
    letter-spacing: 0px;
  }

  .text-COR_PRINCIPAL {
    color: var(--COR_PRINCIPAL);
    letter-spacing: 0px;
  }

  .date-picker {
    max-width: 200px!important;
  }

  .table tr {
    width: 100%;
  }

  .table-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background: #fff;
  }

  /* .table-row:hover {
    background: #f6f6f6 !important;
  } */

  .v-btn {
    display: inline-block;
  }

  /*-----------------------------------*/
  /*------ RESPONSIVE GENERAL ------*/
  /*-----------------------------------*/
  .v-toolbar,
  .v-sheet {
    display: flex !important;
    flex-direction: column !important;
    padding: 0px !important;
  }

  .v-toolbar__content {
    padding-left: 0px !important;
  }

  .v-toolbar__title {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .icon-action {
    font-size: 1.3rem !important;
  }

  .container-principal {
    max-width: 100%;
    margin: auto !important;
  }

  .container-fundo {
    height: calc(100vh -30px) !important;
  }

  .container-rounded {
    background: #fff !important;
    border-radius: 12px !important;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    font-size: 1.5rem !important;
    height: 22px !important;
    background-color: rgb(245, 245, 245) !important;
    /* padding: 0; */
  }

  .data-table-impressos-fundo {
    background-color: rgb(235, 235, 239) !important;
  }

  .data-table-impressos > .v-data-table__wrapper > table > thead > tr > th  {
    background-color: rgb(245, 245, 245) !important;
  }

  .data-table-impressos {
    background-color: rgb(255, 255, 255) !important;
  }

  .data-table-principal {
    background-color: rgb(255, 255, 255) !important;
  }

  .data-table-principal > .v-data-table__wrapper > table > thead > tr > th  {
    background-color: rgb(245, 245, 245) !important;
  }

  .data-table-principal > .v-data-table__wrapper > table > tbody > tr > th  {
    background-color: rgb(2, 245, 245) !important;
    height: 20px !important;
  }

  #virtual-scroll-table-tablet {
    display: none;
  }

  /* .table {
    display: block;
    table-layout: fixed;
    overflow: hidden;
    width: 100% !important;
    min-height: 300px !important;
  }

  .table-td {
    display: block;
    table-layout: fixed;
    overflow: hidden;
    width: 100% !important;
    min-height: 330px !important;
    height: 30px !important;
  } */

  .card-container {
    padding: 16px!important;
  }

  .false-filter {
    background-color:#6C87A2;
    height: 38px!important;
    min-width: 400px!important;
    /* max-width: 900px!important; */
    border-radius: 5px;
    position: relative;
  }

  .false-text {
    width: calc(100%);
    text-align: left;
    font-size: 17px;
    font-weight: 500;
    color: #C9CBCA;
    cursor: pointer;
    overflow-x: hidden;
  }

  .false-icon {
    cursor: pointer;
  }

  .v-select__slot {
    display: none !important;
  }

  .checkbox {
    margin-bottom: 3px;
    margin-top: 1px;
  }

  /* ::v-deep .v-data-footer {
    align-content: center;
    color: var(--COR_PRINCIPAL) !important;  
    font-size:14px !important;
    height: 35px;
    padding-top: 10px !important;
  }

  ::v-deep .v-data-footer__select {
    display: none;
  }
 */

 .status_aberto_vencer {
    font-size: 12px !important;
    /* background: #6C87A2; */
    background: rgb(242, 192, 98);
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }
  .status_aberto_vencida {
    font-size: 12px !important;
    /* background: #6C87A2; */
    background: #FF5252;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }  

  .status_pago {
    font-size: 12px !important;
    background: #469c47;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }

  .status_inativo {
    font-size: 12px !important;
    background: #525252;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }

  .status_rescindido {
    font-size: 12px !important;
    background: #A2A2A2;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }


  .width-nome {
    display: block;
    min-width: 250px;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    z-index: 3;
  }

  .width-parcela {
    min-width: 120px;
    white-space: nowrap;
  }

  .width-nome-customer {
    white-space: nowrap;
  }

  .width-empreendimento {
    display: block;
    min-width: 200px;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    z-index: 3;
  }

  .linhas {
    height:55px;
    background-color:transparent;
  }

  .linhas2 {
    height:55px;
    background-color:transparent;
  }

  /* PEGA OS ESTILOS DO BLOCO ACIMA E MUDA SÓ O PADDING */
  .status_legend {
    font-size: 12px !important;
    font-weight: 400!important;
    padding: 2px 2px 2px 12px ;
    border-radius: 13px !important;
  }

  .status_legend:hover {
    opacity: 0.5;
  }

  .status_legend_a_vencer {
    background: rgb(242, 192, 98);
  }

  .status_legend_vencidas {
    background: #FF5252;
  }

  .status_legend_pagos {
    background: #469c47;
  }

  .status_a_vencer_atraso_color {
      color: red;
  }

  .status_baixado {
    background: #EF5350;
  }

  .status_pago-atraso {
    background: #FFA726;
  }

  .status_nao-encontrado {
    background: #7E57C2;
  }

  .status_msg-banco {
    background: #BDBDBD;
  }

  .status_invalido {
    background: #F44336;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    font-size: 1.5rem !important;
  }

  ::v-deep .checkbox-label label {
    color: var(--COR_SECUNDARIA) !important;  
    font-size:14px !important;
  }

  
.status_ativo {
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_pendente {
  font-size: 14px !important;
  background: goldenrod;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_legend_selecionados {
  background: #6C87A2;
}

.status_cancelado {
  font-size: 14px !important;
  background: #ff5252;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.v-menu__content {
    z-index: 204!important;
}
</style>


